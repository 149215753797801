<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">创新成果获肯定！禹联网络智慧货运平台获评 “2022年度网络货运平台应用优秀案例”</div>
                <div class="article-time"></div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic5_1.jpg" alt="">
                </div>
                <div class="article-p">12月20日，由中国物流与采购联合会主办，中国物流与采购联合会网络事业部、中国物流与采购联合会物流信息服务平台分会等承办的“2022（第十四届）中国物流与供应链信息化大会”在长沙召开。</div>

                <div class="article-p-title">禹联网络智慧货运平台项目获评“2022年度网络货运平台应用优秀案例”</div>
                <div class="article-p">本届中国物流与供应链信息化大会上，禹联网络智慧货运平台项目获评“2022年度网络货运平台应用优秀案例”，是在坚持数字化重塑公路货运行业发展方向的前提下，行业协会及专家对禹联网络助力物流企业数字化转型，降本增效实践成果的肯定与鼓励。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic5_2.jpg" alt="">
                </div>
                <div class="article-p">2022（第十四届）中国物流与供应链信息化大会主题是“数字化重塑产业新生态”，为物流与供应链产业相关方搭建交流合作平台，共同探讨行业发展变革、分享产业时代变化，引导企业认知升级。来自政府、企事业单位、行业领域的领导专家共同出席此次大会。</div>
                <div class="article-p">会上解读了国家有关物流与供应链信息化方面的政策文件、与会嘉宾围绕“数字经济背景下供应链体系建设”、“产业互联网模式创新与价值创造”、“网络货运平台创新与转型”、“物流与供应链信息化优秀案例总结、发布及推广”等专题展开了深入互动和交流。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic5_3.jpg" alt="">
                </div>
                <div class="article-p">作为助力物流行业数字化转型的公路货运数字化承运平台，禹联网络将不负所望，继续秉持“让数字物流，服务美好生活”的企业使命，用真诚之心赢取广大合作企业的信赖，同时也会凭借物联网技术与数字园区的双重优势，全面助力中小物流企业向信息化、平台化、合规化转型发展，为客户、行业创造更大的价值并回报社会。</div>
                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_5'
    }
</script>