<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">禹联网络荣获“安徽省科技型中小企业”认定</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p">近日，安徽省科学技术厅公布了安徽省2023年第8批入库科技型中小企业名单。禹联网络科技有限公司成功通过评审，认定成为“安徽省科技型中小企业“并入库。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic16_1.jpg" alt="">
                </div>
                <div class="article-p">科技型中小企业是指依托一定数量的科技人员从事科学技术研究开发活动，取得自主知识产权并将其转化为高新技术产品或服务，从而实现可持续发展的中小企业。此项资质荣誉是科技部门对企业创新能力的认证标志，也是对企业在自主知识产权、研发管理水平、人才结构等方面的认可。</div>
                <div class="article-p">禹联网络作为一家综合型公路货运数字化承运平台，依托大数据、物联网等数字化技术， 整合分散资源、缩短交易链条，高效链接各用户端（货主、物流公司、司机）。构建物流业务数字化，实现线下物流线上化，带动流程、调度、服务、结算等全链条数字化升级。</div>
                <div class="article-p">公司始终重视产品研发和技术创新，持续研发投入和人才建设。近日，安徽省科学技术厅公布安徽省2023年第8批入库科技型中小企业名单，禹联网络位列榜单并入库。体现了禹联在自主研发和创新能力方面的持续进步，也标志着公司在数字货运道路上的探索与创新，得到了政府部门的认可，对于公司的未来发展提振了更大信心。</div>
                <div class="article-p">未来，禹联网络将继续秉承创新、诚信、专业、共赢的核心价值观，致力于为客户提供更优质、更全面的数字化解决方案，更加重视技术、人才、生态的创新发展，再接再厉，在物流科技创新与数字转型发展道路上持续贡献自己的力量！</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_16'
    }
</script>