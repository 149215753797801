<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 行业观察 &gt; 详情</div>
                <div class="article-title">禹联观察丨好风向！一图带你详细了解“平台经济”</div>
                <div class="article-time">  </div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p">2022年初国家先后推出了两项“平台经济”相关的重磅政策。</div>
                <div class="article-p">1月12日，由国务院发布的《“十四五”数字经济发展规划》， 标志着我国数字经济领域的首部国家级专项规划正式出台，文件中共有35处提到了“平台”。</div>
                <div class="article-p">1月19日，国家发展改革委、市场监管总局、中央网信办、工业和信息化部等九部门联合印发《关于推动平台经济规范健康持续发展的若干意见》 ，进一步明确了要从构筑国家竞争新优势的战略高度出发，坚持发展和规范并重，推动平台经济规范健康持续发展的总体思路。</div>
                <div class="article-p">利好消息接踵而至，平台经济焕发新机。</div>
                <div class="article-p">关于平台经济你了解多少呢？《禹联图说》新一期带你走近“平台经济”</div>


                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic6_1.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic6_2.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic6_3.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic6_4.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic6_5.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic6_6.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic6_7.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic6_8.jpg" alt="">
                </div>
                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail3_6'
    }
</script>