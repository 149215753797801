<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 行业观察 &gt; 详情</div>
                <div class="article-title">禹联观察丨重塑物流生态，AI大模型加速落地!</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic8_1.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic8_2.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic8_3.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic8_4.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic8_5.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail3_8'
    }
</script>