<template>
    <div class="fixed-right-bottom">
        <div class="fixed-item">
            <div class="fixed-img1">
                <img src="../images/home/p-icon2.png" alt="" width="100%" />
            </div>
            <div class="fixed-pop">
                <div class="fixed-pop-scan">
                    <div class="item">
                        <img src="../images/home/footer-right-pop-weixin.png" width="100%" alt="">
                        <div>禹联网络公众号</div>
                    </div>
                    <div class="item">
                        <img src="../images/home/footer-right-pop-video.png" width="100%" alt="">
                        <div>禹联网络视频号</div>
                    </div>
                </div>
                <div class="fixed-pop-btn">微信扫码 即刻了解</div>
            </div>
        </div>
        <div class="right-separator"></div>
        <div class="fixed-item">
            <div class="fixed-img1">
                <img src="../images/home/p-icon1.png" width="100%"/>
            </div>
            <div class="fixed-pop">
                <div class="fixed-pop-scan">
                    <div class="item">
                        <img src="../images/home/footer-right-pop-company.png" width="100%" alt="">
                        <div>企业端小程序</div>
                    </div>
                    <div class="item">
                        <img src="../images/home/footer-right-pop-driver.png" width="100%" alt="">
                        <div>司机端小程序</div>
                    </div>
                </div>
                <div class="fixed-pop-btn">微信扫码 即刻使用</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FixedRightBottom'
}
</script>

<style lang="less" scoped>
    .fixed-right-bottom{
        position: fixed;
        right: 0.2rem;
        bottom: 1.6rem;
        display: flex;
        flex-direction: column;
        background: linear-gradient(to bottom, #1d5cb6, #0586eb);
        width: 0.5rem;
        height: 1.5rem;
        border-radius: 0.25rem;
    }
    .fixed-right-bottom .fixed-item{
        position: relative;
        text-align: center;
        flex: 1;
    }
    .fixed-item .fixed-img1{
        margin: 0.18rem auto;
        width: 0.36rem;
    }
    .fixed-item:hover .fixed-pop{
        display: block;
    }
    .fixed-right-bottom .fixed-pop{
        position: absolute;
        width: 3.3rem;
        right: 0.5rem;
        top: -1rem;
        background: #fff;
        box-shadow: 0 0 0.1rem 0.02rem #dee6ec;
        padding: 0.15rem 0.2rem;
        display: none;
    }
    .fixed-pop-scan{
        display: flex;
        align-items: center;
    }
    .fixed-pop-scan .item{
        flex: 1;
    }
    .fixed-pop-scan div{
        color: #9aa7b1;
        font-size: 0.16rem;
        padding-bottom: 0.08rem;
        padding-top: 0.08rem;
    }
    .fixed-pop-btn{
        color: #0686eb;
        font-size: 0.14rem;
        padding: 0.1rem 0;
        border: 2px solid #7abef4;
        border-radius: 0.05rem;
    }
    .right-separator{
        flex-shrink: 0;
        height: 0.02rem;
        background: #649bda;
        width: 0.4rem;
        margin: 0 auto;
    }
</style>