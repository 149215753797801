<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">禹联网络上榜“2021中国交通运输新业态百强企业”</div>
                <div class="article-time"></div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic9_1.png" alt="">
                </div>
                <div class="article-p">瑞雪兆丰年，大雪节气再添喜讯！刚刚，由中国交通运输协会及《人民交通》杂志社共同主办的 “2021中国交通运输新业态百强企业”评选名单正式揭晓，禹联网络荣耀上榜！</div>
                <div class="article-p">在数字化时代背景下，交通运输行业同与云计算、大数据等新技术进行深度融合催生了新业态新模式的发展。“ 2021中国交通运输新业态百强企业”榜单评选，以具有前瞻性的洞察力评选出为构建生态文明交通，积极探索新业态新模式，推动交通运输产业更高效、更便捷的升级发展做出贡献的100家优秀企业。</div>
                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name:'Detail1_9'
    }
</script>