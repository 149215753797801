<template>
    <div>
        <!-- banner图 -->
        <div class="contact-banner">
            <img src="../images/contactus/banner.png" width="100%" alt="">
        </div>

        <!-- 网络货运平台 -->
        <div class="contact-form-wrapper" id="title1">
            <div class="container-wrapper network">
                <div class="title1 fontsize55 wow animated fadeInDown">禹联网络智慧货运平台</div>
                <div class="title2 fontsize28 wow animated fadeInDown">
                    <div>服务物流企业数字化转型 专业运营团队一站式解决方案</div>
                </div>
                <div class="form-wrapper wow animated bounce">
                    <form id="contact-form" action="https://formspree.io/f/mzbqqqzl" method="POST"
                          @submit.prevent="formSubmit">
                        <div class="name-email">
                            <div class="name-email-item">
                                <div class="fontsize24">企业名称<span>*</span></div>
                                <input type="text" name="company" placeholder="请输入您的企业名称" required>
                            </div>
                            <div class="name-email-item">
                                <div class="fontsize24">姓名<span>*</span></div>
                                <input type="text" name="name" placeholder="请输入您的姓名" required>
                            </div>
                        </div>
                        <div class="name-email">
                            <div class="name-email-item">
                                <div class="fontsize24">联系电话<span>*</span></div>
                                <input type="tel" name="phone" placeholder="请输入您的手机号码" required>
                            </div>
                            <div class="name-email-item">
                                <div class="fontsize24">地址<span>*</span></div>
                                <input type="text" name="address" placeholder="请输入您所在区域" required>
                            </div>
                        </div>
                        <div class="name-email">
                            <div class="name-email-item">
                                <div class="fontsize24">如果你有想了解的信息或疑问，欢迎留言！</div>
                                <textarea placeholder="请陈述您的需求" name="message"></textarea>
                            </div>
                        </div>
                        <button type="submit" class="fontsize30 rts-btn">{{formBtnText}}</button>
                    </form>
                    <div class="form-wrapper-tips fontsize24">请留下您的联系方式，我们将尽快与您联络</div>
                </div>
            </div>
        </div>

        <!-- 联系我们 -->
        <div class="contact-form-wrapper contact-map" id="title2">
            <div class="container-wrapper network">
                <div class="title1 fontsize55 wow animated fadeInDown">联系我们</div>
                <div class="title2 fontsize28 wow animated fadeInDown">
                    <div>服务数字转型 业务覆盖全国</div>
                </div>
                <div class="map-wrapper">
                    <img src="../images/contactus/map.jpg" alt="" width="100%">
                    <div class="map-pop wow animated bounceIn">
                        <img src="../images/contactus/map-pop.png" alt="" width="100%">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery';
    import WowMixin from "@/WowMixin";

    const loadingText = '发送中...'
    const formText = '预约咨询';

    export default {
        name: 'ContactUs',
        mixins: [WowMixin],
        data() {
            return {
                formBtnText: formText,
            }
        },
        methods: {
            formSubmit() {
                if (this.formBtnText === loadingText) return;

                const obj = $('#contact-form').serialize();
                this.formBtnText = loadingText;
                $.ajax({
                    type: 'post',
                    url: 'https://formspree.io/f/mzbqqqzl',
                    async: true,
                    data: obj,
                    dataType: 'json',
                    success: () => {
                        alert('邮件已发送!!!');
                    },
                    error: () => {
                        alert('未知错误,发送失败');
                    },
                    complete: () => {
                        this.formBtnText = formText;
                        $('#contact-form')[0].reset();
                    }
                })
            }
        }
    }
</script>

<style>

</style>