import Vue from 'vue'
import App from './App.vue'
import less from 'less'
import {isMobile} from "@/utils";
import router from './router/index.js'

import 'swiper/css/swiper.min.css'
import 'wowjs/css/libs/animate.css'
import './assets/styles/reset.css'
import './assets/styles/common.css'

Vue.use(less)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this;

    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
    if (isMobile()){
      //移动端使用
      setTimeout(() => {
        setRem();
      })
      window.addEventListener('resize', setRem);//浏览器窗口大小改变时调用rem换算方法
    } else{
      //pc端使用
      setTimeout(() => {
        setRemPC()
      })
      window.addEventListener('resize', setRemPC);//浏览器窗口大小改变时调用rem换算方法
    }
  },
}).$mount('#app')

// 手机端
function setRem() {
  var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
  var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
}

//pc端
function setRemPC() {
  var whdef = 100 / 1920; // 表示1920的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
  var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
}
