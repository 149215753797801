<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">“情暖重阳 敬老爱老”—禹联网络养老院献爱心</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic18_1.jpg" alt="">
                </div>
                <div class="article-p-title">金秋十月桂花香，重阳敬老公益行！</div>
                <div class="article-p">重阳节前夕，禹联网络公益行队伍带着深深的敬意和关怀，前往华康养老院探望和慰问这里的老人们。天气转凉，我们为老人们送去保暖裤、水果、牛奶、老年人喜爱的零食，同时也带去了禹联网络领导及全体员工的真诚问候和祝福，祝愿老人们度过一个温馨愉快的重阳节。</div>
                <div class="article-p">华康养老院是一所医养结合型养老机构，这里整洁干净，舒适温馨，拥有完善的医护、保健、洗浴等配套设施。养老院刘院长带领着医护人员们，用她们的专业知识和爱心，为老人们提供了全方位、舒适的生活服务。在这里，老人们感受到家的温暖，在温馨的环境中安享晚年。</div>
                <div class="article-p-title">跨越时代的温暖交流</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic18_2.jpg" alt="">
                </div>
                <div class="article-p">在刘院长的介绍下，我们与爷爷奶奶们聊起了家常。虽然年事已高，但他们脸上都洋溢着喜悦和热情，像家里的长辈一样亲切。一位因康复需要躺卧的奶奶，很喜欢唱戏，看到我们很高兴唱了一小段戏曲，韵味十足，大家拍手称赞。坐在轮椅上的爷爷，在做手操，微笑着和我们打招呼。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic18_3.jpg" alt="">
                </div>
                <div class="article-p-title">图：102岁邹奶奶和禹联00后新员工</div>
                <div class="article-p">养老院的明星大人物，是102岁的老寿星邹奶奶。年逾百岁，精神状态很好，还爱唱歌。刘院长说上句歌词，邹奶奶下一句接的非常流利。禹联网络公益行队伍中，有一位00后新员工小白。邹奶奶听说小白刚参加工作不久，一字一句的祝他好好工作，一切顺利！得到老人家的祝福，小白非常开心，也祝邹奶奶健康长寿！</div>
                <div class="article-p">这是一场跨越时代，两位“00”后的交流。一幕幕温馨的画面，让我们深深的感受到，人到老年，一样可以充满活力。</div>
                <div class="article-p">尊老、敬老是中华民族的传统美德，禹联网络将继续弘扬这一精神，以实际行动关爱身边的老年人。多些陪伴，多些关怀！在重阳节这个寓意美好的日子里，禹联网络祝愿天下所有的老年人，身体健康、老有所养，老有所乐！</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_18'
    }
</script>