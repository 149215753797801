<template>
    <div id="app">
        <com-header/>
        <router-view :key="path"/>
        <com-footer/>
    </div>
</template>

<script>
    import ComHeader from "@/components/ComHeader";
    import ComFooter from "@/components/ComFooter";

    export default {
        name: 'App',
        components: {
            ComHeader,
            ComFooter
        },
        mounted() {            
            this.$bus.$on('menuClick', (path, anchor, isExternal, subPath, type) => {
                // 切换到对应页面
                if(!isExternal){
                  this.$router.push({
                    path,
                    query: {id: anchor}
                  });
                }else{
                  if(type=='link'){                    
                    var newWin = window.open('about:blank')
                    newWin.location.href = subPath
                  }else{
                    window.open(subPath)
                  }
                }
                
                
                // 滚到对应锚链接
                setTimeout(() => {
                    const element = document.getElementById(anchor);
                    element && element.scrollIntoView();
                }, 100)
            });
        },
        computed: {
            path() {
                return this.$route.fullPath
            }
        },
        beforeDestroy() {
            this.$bus.$off('menuClick');
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
</style>
