<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">禹联网络荣获中国数字货运平台十佳推荐金牌服务商</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic14_1.jpg" alt="">
                </div>
                <div class="article-p">2023年8月26日，由中国交通运输协会指导，《人民交通》杂志社主办的“2023 中国物流高质量生态发展年会暨ESG高峰论坛”在上海成功召开，来自物流行业头部企业、专家学者、企业代表及嘉宾齐聚一堂。</div>
                <div class="article-p">本次大会对“2022-2023年度中国城市物流发展创新成果与优秀案例”进行了表彰。经过专家组委会评选，禹联网络被评选为“2023年中国数字货运平台十佳推荐金牌服务商”。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic14_2.jpg" alt="">
                </div>
                <div class="article-p">本次大会主题为“万物持续 ESG同行”，大会重点关注ESG（即环境、社会和公司治理）新的经营理念与物流的融合发展，探索出更多兼具商业价值与社会价值的业务实践，进一步推动交通物流行业数字化转型与可持续发展，共创绿色数字货运新路径。</div>
                <div class="article-p">与会嘉宾纷纷从自身经历及行业现状出发，围绕“物流供应链解决方案”“绿色物流实践与创新”“AI大模型物流行业应用”“物流行业践行ESG成果展示”等专题开展了深入互动和交流。</div>
                <div class="article-p">成立三年以来，禹联网络始终坚持服务物流企业实现数字化转型，以建设“安全、开放、赋能、共享”的平台生态体系为目标，助力中小物流及企业降本增效。2022年公司自主研发的禹联智慧货运平台正式上线，持续拓宽禹联全场景业务服务能力，实现更加精细化和高效的运营管理。在行业竞争加剧的市场环境下，帮助合作伙伴提升竞争力，实现可持续增长。</div>
                <div class="article-p">此项荣誉的获得，不仅是对禹联网络服务物流企业数字化转型，以及助力公路货运行业节能减排、绿色发展的实践认可，同时见证了禹联网络成立三年以来，持续在物流数字化领域的持续精进和深耕。</div>
                <div class="article-p">未来，禹联网络将继续秉承创新、诚信、专业、共赢的核心价值观，致力于为客户提供更优质、更全面的数字化解决方案，为推动物流行业的低碳和发展贡献力量！</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_14'
    }
</script>