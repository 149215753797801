<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">禹联网络与国都证券举行战略合作签约仪式！</div>
                <div class="article-time"></div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic2_1.jpg" alt="">
                </div>
                <div class="article-p">2月14日，禹联网络科技有限公司同国都证券股份有限公司正式签署战略合作协议。未来，双方将在数字供应链、车联网、新能源车、融资融券、数字金融等方面，展开全方位、宽领域、高层次的深度合作，推动双方金融资源和企业资源优势叠加，发挥资本市场枢纽作用实现互利共赢。</div>

                <div class="article-p-title">禹联网络与国都证券举行战略合作签约仪式</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic2.png" alt="">
                </div>
                <div class="article-p">2月14日，禹联网络科技有限公司同国都证券股份有限公司正式签署战略合作协议。</div>
                <div class="article-p">禹联网络科技有限公司与国都证券股份有限公司战略合作协议签约仪式在北京举行。禹联网络董事长赵宏，国都证券副总经理储刚汉分别代表双方进行签约。</div>
                <div class="article-p">国都证券副总经理储刚汉，投行部执行董事胡静静，执行董事娄家杭，执行董事许金洋，项目经理李嘉炜、项目经理王喆；禹联网络联合创始人王学军，禹联网络总经理张国宇、CTO石迎春共同出席签约仪式。</div>
                <div class="article-p">本次战略合作协议的签署，旨在推动双方在数字供应链、车联网、新能源车、融资融券、数字金融等方面，展开全方位、宽领域、高层次的深度合作，推动双方金融资源和企业资源优势叠加，发挥资本市场枢纽作用实现互利共赢。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic2_2.jpg" alt="">
                </div>
                <div class="article-p-title">01 聚焦数字货运生态化建设</div>
                <div class="article-p">禹联网络董事长赵宏介绍了禹联网络的发展概况、产业布局和未来规划，期待双方继续开展广泛且深入的合作，实现互信共赢，更健康、更长远的发展。 </div>
                <div class="article-p">成立以来，禹联网络聚焦建设“安全、开放、赋能、共享”的综合型公路货运数字化承运平台，一直保持高速发展势头，在业务发展、研发创新、组织建设、品牌宣传等各方面都取得了不错的成果。</div>
                <div class="article-p">凭借合规化、平台化、数字化能力，禹联网络在高速发展的网络货运行业中脱颖而出，助力物流企业降本增效向数字化转型升级，为物流行业高质量发展贡献力量。</div>

                <div class="article-p-title">02 推动金融资源和企业资源优势叠加</div>
                <div class="article-p">国都证券副总经理储刚汉,对禹联网络发展概况、未来规划以及行业发展方向上给予了高度评价。 </div>
                <div class="article-p">他表示，国都证券作为由中国证监会批准建立的综合性证券公司，在数字金融、资本运作、融资融券等方面具有丰富的市场经验。希望通过此次战略合作的签订，能够充分发挥公司在资本市场服务领域的专业优势，为禹联网络加速迈向资本市场提供稳定的支撑。</div>
                <div class="article-p">未来，双方将借助各自优势资源，在多领域开展深入合作，实现互利共赢。</div>
                <div class="article-p">签约现场，双方围绕未来合作中的具体问题及业务细节进行了深入讨论与交流。接下来，期待双方能够充分发挥各自在金融资源和企业资源的优势，实现互利共赢。</div>
                <div class="article-p">禹联网络正迈入新一轮高增长周期，用实际行动践行“让数字物流，服务美好生活”的企业使命，实现“行业先进的物流生态链数字化服务商”的企业愿景。</div>

                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_2'
    }
</script>