<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 行业观察 &gt; 详情</div>
                <div class="article-title">2023 中国供应链物流创新科技报告</div>
                <div class="article-time">2023年2月9日</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p">罗戈研究⾃2015年开始追踪国内供应链物流领域的创新科技应⽤、发展及趋势，我们每年举办创新科技峰会、收集企业案例、总结行业现状、发布研究 报告。在过往行业追踪与沉淀的基础上，《2023中国供应链物流创新科技报告》，我们提出了新的认知框架—— </div>
                <div class="article-p">在数字世界，以端到端供应链数据驱动的智慧计划（SCP）、执⾏（SCE）、物流管理（LMS），指导物理世界的运营、诊断与持续优化，并⽀撑企业⼀体化供应链管控视图（供应链控制塔） ； 报告。在过往行业追踪与沉淀的基础上，《2023中国供应链物流创新科技报告》，我们提出了新的认知框架—— </div>
                <div class="article-p">在物理世界，通过智能化设备，提升运作效率，并实时反馈物流运作节点的数据，⽀撑数字世界的可视化，并基于数字世界的优化结果，持续改善。并对近年各细分领域的变化、特征、案例及趋势进⾏梳理和总结。 ； 报告。在过往行业追踪与沉淀的基础上，《2023中国供应链物流创新科技报告》，我们提出了新的认知框架—— </div>
                <div class="article-p">这份报告涉及供应链物流技术趋势与创新图谱、供应链数智化、科技供应链解决⽅案、物流数智化、趋势总结等内容，包含菜鸟物流、顺丰同城、京东物流、极智嘉、旷视、富勒等超百家物流行业相关企业科技产品方案。汇集了供应链物流科技领域的方方面面，是近几年对整个物流科技罕见的全面性、综合性、整体性研究成果发布。 ； 报告。在过往行业追踪与沉淀的基础上，《2023中国供应链物流创新科技报告》，我们提出了新的认知框架—— </div>

                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic1_1.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic1_2.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic1_3.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news3_pic1_4.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail3_1'
    }
</script>