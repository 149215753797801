<template>
    <div>
        <!-- banner图 -->
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="single-product-wrapper-h2">
                        <img src="../images/home/banner1.png" width="100%" height="100%" alt="">
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="single-product-wrapper-h2">
                        <img src="../images/home/banner2.png" width="100%" height="100%" alt="">
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="single-product-wrapper-h2">
                        <img src="../images/home/banner3.png" width="100%" height="100%" alt="">
                        <router-link class="toDigitalPage fontsize30" to="/digital">查看详情</router-link>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="single-product-wrapper-h2">
                        <img src="../images/home/banner4.png" width="100%" height="100%" alt="">
                    </div>
                </div>
            </div>
            <!-- 分页器 -->
            <div class="swiper-pagination"></div>
            <!-- 导航按钮 -->
            <div class="swiper-button-prev" style="color: #4545e6"></div>
            <div class="swiper-button-next" style="color: #4545e6"></div>
        </div>

        <!-- 产品服务体系 -->
        <div class="container-wrapper">
            <div class="first-title fontsize55 text-center wow animated fadeInDown">产品服务体系</div>
            <div class="content1">
                <div class="content1_1">
                    <div class="system-list-1 wow animated bounceInLeft">
                        <div class="system-title fontsize28">智慧物流数字云平台</div>
                        <div class="system-content fontsize16">通过数字化协同系统，打通物流供应链全流程，助力企业数字化转型</div>
                    </div>
                </div>
                <div class="content1_2 wow animated bounceInRight">
                    <div class="system-list-2 system-2">
                        <div class="system-title fontsize28">供应链智慧运营中心</div>
                        <div class="system-content fontsize16">数据线上化、共享化、可视化；通过精细化运营管理，帮助客户优化服务提高效率</div>
                    </div>
                    <div class="system-list-2 system-4">
                        <div class="system-title fontsize28">车后服务市场</div>
                        <div class="system-content fontsize16">完善集车辆保险、维修与保养、车辆租赁等为一体的服务生态</div>
                    </div>
                </div>
                <div class="content1_2 wow animated bounceInRight">
                    <div class="system-list-2 system-3">
                        <div class="system-title fontsize28 ">大宗行业定制组件平台</div>
                        <div class="system-content fontsize16">一对一定制解决方案，满足客户多种运输需求，有效支持复杂场景及多元业务形态</div>
                    </div>
                    <div class="system-list-2 system-5">
                        <div class="system-title fontsize28">数据信用链体系</div>
                        <div class="system-content fontsize16">释放数字资产和信用价值，构建以大数据为基础的信用体系</div>
                    </div>
                </div>
            </div>
        </div>

        <!--智慧物流数据驱动，智慧物流-->
        <div class="area-block">
            <div class="container-wrapper wuliu-container">
                <div class="wuliu-container-left wow animated slideInLeft">
                    <img src="../images/home/data-left.png" width="100%" alt="">
                </div>
                <div class="wuliu-container-right  wow animated slideInRight">
                    <img src="../images/home/data-right.png" width="100%" alt="">
                </div>
            </div>
        </div>

        <!-- 新闻资讯 -->
        <div class="home-news-wrapper">
            <div class="container-wrapper">
                <div class="first-title fontsize55 text-center wow animated fadeInDown">新闻资讯</div>
                <div class="home-news">
                    <div class="home-news-item  wow animated flipInX" v-for="item in newsList" :key="item.id" @click="toDetail(item)">
                        <div class="thumbnail">
                            <img :src="imgUrl(item.img)" width="100%" alt="">
                        </div>
                        <div class="body">
                            <div class="title fontsize24">{{item.label}}</div>
                            <div class="content">
                                <span></span>
                                <router-link :to="`/news/detail?id=${item.id}`" class="rts-read-more fontsize16">详情</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 右下角悬浮 -->
        <fixed-right-bottom v-if="!isMobile"/>
    </div>
</template>

<script>
    import Swiper from 'swiper';
    import FixedRightBottom from '@/components/FixedRightBottom'
    import {isMobile} from "@/utils";
    import WowMixin from "@/WowMixin";

    export default {
        name: 'HomeComp',
        components: {FixedRightBottom},
        mixins: [WowMixin],
        data() {
            return {
                isMobile: isMobile(),
                newsList: [
                    {label: '好的营商环境实现企业追求与政府支持“同频共振”', img: 'news1', id: '2_6'},
                    {label: '"禹"行业同存 "联"科技共创', img: 'news2', id: '2_1'},
                    {label: '禹联入选《2023中国供应链物流创新科技报告》', img: 'news3', id: '1_3'},
                    {label: '禹联获评运联网“2022-2023年最具成长力企业”', img: 'news4', id: '1_1'},
                ],
            }
        },
        mounted() {
            this.$nextTick((() => {
                setTimeout(() => {
                    new Swiper('.swiper-container', {
                        loop: true,
                        autoplay: true,
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                        pagination: {
                            el: '.swiper-pagination', // 包裹小圆点的类名，可自定义
                            clickable: true, // 点击小圆点，也可以滚动轮播图。默认false
                        },
                    })
                }, 500)
            }))
        },
        methods: {
            imgUrl(img) {
                return require('@/images/home/' + img + '.png')
            },
            toDetail(item){
                this.$router.push(`/news/detail?id=${item.id}`)
            }
        }
    }
</script>e>