<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 媒体报道 &gt; 详情</div>
                <div class="article-title">禹联网络创始人赵宏：面对高峰低谷，保持坚韧和乐观，做长期主义者</div>
                <div class="article-time">来源：运联网 2023-01-14</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p">罗曼·罗兰曾说过，这个世界上只有一种真正的英雄主义，那就是认清生活的真相，并且仍然热爱它。</div>
                <div class="article-p">对于赵宏来说，崎岖的创业征途上，每一个或深或浅的脚印，都凝聚着他坚定的个人信条和对未来的畅想，于禹联网络货运平台也是如此。</div>
                <div class="article-p-title">创业令人着迷</div>
                <div class="article-p">赵宏，出生于甘肃省嘉峪关。在这个号称“天下第一雄关”的地方，赵宏度过了自己美好的童年。或许是在这样的环境下成长的原因，赵宏对于任何事一直抱有好奇心和冒险精神。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news2_pic2.png" alt="">
                </div>
                <div class="article-p">2014年，赵宏开始了对行业内社区团购创业项目的最早尝试。但由于缺乏经验，在项目发展的节点，其投资未及时到位，项目被迫终止。</div>
                <div class="article-p">欣慰的是，那批曾经一起奋斗过的团队成员，如今也已成为相关领域内的优秀人才。</div>
                <div class="article-p">赵宏在自己的朋友圈里写过这样一段话：“创业不是一段经历，而是一群人，为了同一个目标勇敢奋斗的伙伴......”</div>
                <div class="article-p">我想，创业最吸引赵宏的是，它就像一个升级打怪的过程，需要带领团队不断自我迭代，对行业持续探索，勇攀高峰。</div>
                <div class="article-p">2020年6月23日，在蚌埠禹投集团（国企）与禾水企业管理服务中心共同出资下，禹联网络正式成立，并定位于一家基于物联网科技和数字化园区联动打造的公路货运数字化承运平台。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news2_pic2_1.jpg" alt="">
                </div>
                <div class="article-p">在赵宏的创业生涯中，无论是社区团购、生鲜食材配送平台、农村物流平台，还是现在的网络货运平台，这背后都有一个共同的驱动因素，就是数字科技。</div>
                <div class="article-p">“6年的发展，让越来越多的从业者关注到网络货运这个行业。据不完全统计数据显示，目前网络货运单看牌照的话已经超过2300张，这2300多张牌照整合了497万驾驶员，社会零散运力549万辆。网络货运平台线上交易额占整个公路整车运输市场的比例接近11%。所以说，整个网络货运行业不仅整合效果明显，市场容量也是非常大的。”赵宏说到。</div>
                <div class="article-p">目前，禹联网络初步形成了以智慧物流数字云平台、供应链智慧运营中心、大宗行业定制组件平台、车后服务市场、数据信用链体系在内的五大产品服务体系，以及为客户提供“一站式”数字化解决方案的能力。</div>
                <div class="article-p-title">用技术进行链接和赋能</div>
                <div class="article-p">事实上，赵宏从未动摇过对用技术驱动数字化平台的探索。</div>
                <div class="article-p">在他看来，近些年随着公路货运市场竞争的持续加剧，市场影响因素已经发生了很多变化，其中变化最大的是货主。货主对于运输过程的需求和服务水平要求的更高了，尤其是对于技术系统、订单管理、服务效率的需求，并且货主也更看重平台的运营效率和运营质量。</div>
                <div class="article-p">赵宏表示，网络货运的核心价值是实现行业数字化，这点具体体现在链接和赋能上面。</div>
                <div class="article-p">链接是指是要理清链接谁，链接的目的是什么，这是十分重要的？</div>
                <div class="article-p">而赋能是指，有效的赋能应该聚焦于大数据、人才，以及技术等方面，目标是满足行业发展和客户需求。</div>
                <div class="article-p">网络货运平台具有双重身份，既是承运者也是托运人。平台将货主、运力、车辆以及货物聚合在一起，实现了运力资源的整合与优化配置。</div>
                <div class="article-p">其中，整合是指整合业务和整合供应链的能力，这个过程中会出现多种不同维度的整合路径。</div>
                <div class="article-p">行业有两大整合趋势。一方面，是按服务行业的差异划分，选择垂直业务场景进行整合，并发展成为细分市场的专业平台。禹联网络目前针对玻璃、煤炭、钢铁、农产品等运输物梳理出了多种大宗类型客户的基础解决方案模块；另一方面，其按地理区域进行了整合，未来可能在某个区域会出现1—2家综合网络货运平台占据区域主导地位。</div>
                <div class="article-p">同时，网络货运平台具有强监管属性，这体现在货物运输的全流程、资质、交易、运输等受到政府部门的监测管理。</div>
                <div class="article-p">总的来说，网络货运平台促进了整个行业合规性发展，加快了物流行业数字化转型升级。</div>
                <div class="article-p">关于下阶段的发展，禹联网络透露，其将在技术研发和产品服务升级上持续发力。</div>
                <div class="article-p">首先，通过SaaS化服务方式去链接更多的从业者角色，在禹联网络平台上产生更多交易，打造一个真正基于运输生态的交易服务体系。</div>
                <div class="article-p">行业有两大整合趋势。一方面，是按服务行业的差异划分，选择垂直业务场景进行整合，并发展成为细分市场的专业平台。禹联网络目前针对玻璃、煤炭、钢铁、农产品等运输物梳理出了多种大宗类型客户的基础解决方案模块；另一方面，其按地理区域进行了整合，未来可能在某个区域会出现1—2家综合网络货运平台占据区域主导地位。</div>
                <div class="article-p">同时，网络货运平台具有强监管属性，这体现在货物运输的全流程、资质、交易、运输等受到政府部门的监测管理。</div>
                <div class="article-p">总的来说，网络货运平台促进了整个行业合规性发展，加快了物流行业数字化转型升级。</div>
                <div class="article-p">关于下阶段的发展，禹联网络透露，其将在技术研发和产品服务升级上持续发力。</div>
                <div class="article-p">首先，通过SaaS化服务方式去链接更多的从业者角色，在禹联网络平台上产生更多交易，打造一个真正基于运输生态的交易服务体系。</div>
                <div class="article-p">其次，禹联网络接下来会通过跨界融合的方式拓宽发展，包括承接一些线下新能源运输领域的项目，搭建起一个聚焦垂直领域的实体运输网络，通过对实体运输网络的综合服务带动金融供应链产品的实施。</div>
                <div class="article-p">最后，基于垂直领域的探索，禹联网络将和更多具备车联网技术的平台做深度合作。</div>
                <div class="article-p">如今，赵宏带领着禹联团队逐步打磨出了适用于禹联网络的发展模式：集服务产品化、产品工具化、工具数字化、数字生态化于一身的物流数字化运营服务商。</div>
                <div class="article-p">“未来，禹联网络将专注发挥公司的技术优势，升级技术支持和产品服务，不断拓宽全场景业务服务的能力，逐渐丰富金融、保险、新能源产品、车后市场等增值服务，以建设“安全、开放、赋能、共享”的平台生态体系为目标，打造一个真正基于公路货运全产业链服务的生态体系，为整个行业带来价值。”赵宏说到。</div>

                <div class="article-p-title">如何走得更远？</div>
                <div class="article-p">从2014年的车货匹配到2016年的无车承运，再到2020年的网络货运平台，整个市场呈现出百花齐放的局面。</div>
                <div class="article-p">赵宏称，想要成为一家可持续发展的网络货运平台要做到以下几个方面：公司要有专业的团队；要有持续研发的能力；平台和团队能够有能力打造出完整的合规链条；能够规划出基于网络货运业务模式的可持续发展的业务结构。这四个能力缺一不可。作为网络货运平台企业，只有不断提升自己的技术水平和研发能力，这样才能快速的响应和满足客户的需求，把网络货运平台打造得更扎实。</div>
                <div class="article-p">而对于行业的新进入者来说，充足的现金流和团队一致的价值观是支撑企业走的更远的基石。</div>
                <div class="article-p">到目前为止，围绕网络货运行业的发展，国家交通部等部门相继出台了多个政策，在规范网络平台道路货物运输经营，维护道路货物运输市场秩序，促进物流业降本增效方面发挥了重大作用。</div>
                <div class="article-p">如2022年4月10日,《中共中央国务院关于加快建设全国统一大市场的意见》(下称《意见》)发布，《意见》提出，要建设现代流通网络，其中包括大力发展第三方物流，支持数字化第三方物流交付平台建设，推动第三方物流产业科技和商业模式创新，促进全社会物流降本增效。</div>
                <div class="article-p">近日，《“十四五”现代物流发展规划》的发布，更加表明了物流链接的价值在日益凸显。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news2_pic2_2.jpg" alt="">
                </div>
                <div class="article-p">赵宏表示，从国家到地方政府对行业的政策支持，以及地方政府良好的营商环境，为包括禹联在内的网络货运企业的快速发展提供了重要支持。</div>
                <div class="article-p">目前，禹联网络在网络货运行业快速发展的同时，也在推进数字化服务能力等方面持续发力：禹联智慧货运平台系统版本持续迭代升级，完善OCR识别电子签约智能操作，实现与第三方增值服务商系统的对接，构建可视化体系满足用户数据运营和实时查询的信息化需求，深耕车联网技术的开发，大大促进平台物流企业精细化运营与物流管理效率提升，助力平台物流企业提升竞争力，实现降本增效。</div>
                <div class="article-p">“我认为，在不确定的环境下，企业更要保持一个积极心态，做好团队技术储备和现金流，以及价值观的重点实施，实现自我造血能力。创业成功是小概率事件，但保持坚韧和坚持长期主义，小概率事件会提高并发生。事实上，所有企业的胜利，最后都是价值观的胜利，企业家的价值导向会引领企业的战略愿景，我们对于未来市场的信心还是很足的。”赵宏说道。</div>
                <div class="article-p">就如贺炜在世界杯的解说词那样：请不要相信，胜利像山坡上的蒲公英一样唾手可得，但请相信，世上总有美好值得我们全力以赴。</div>

                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail2_2'
    }
</script>