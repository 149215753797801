<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">罗戈研究《2023中国供应链物流创新科技报告》正式发布，禹联网络入选报告</div>
                <div class="article-time"></div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p">2月9日，罗⼽研究及罗⼽⽹发布《2023中国供应链物流创新科技报告》。禹联网络作为数字货运代表企业之一，入选《2023中国供应链物流创新科技报告》。此前，禹联网络获评为“2022LOG年度最具创新力供应链&物流科技优秀企业”。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic3_1.jpg" alt="">
                </div>
                <div class="article-p-title">《2023 中国供应链物流创新科技报告》</div>
                <div class="article-p">报告介绍：罗⼽研究及罗⼽⽹（前“物流沙⻰”）⾃ 2015 年开始追踪国内供应链物流领域的创新科技应⽤、发展及趋势，我们每年举办创新科技峰会、收集企业案例、总结⾏业现状、发布研究报告。</div>
                <div class="article-p">据介绍，《2023中国供应链物流创新科技报告》汇集了供应链物流科技领域的方方面面，是近几年对整个物流科技罕见的全面性、综合性、整体性研究成果发布。</div>
                <div class="article-p-title">《2023 中国供应链物流创新科技图谱》</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic3_2.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic3_3.jpg" alt="">
                </div>
                <div class="article-p-title">禹联网络实力入选《2023中国供应链物流创新科技报告》并展示产品方案</div>
                <div class="article-p">目前，禹联网络初步形成了以智慧物流数字云平台、供应链智慧运营中心、大宗行业定制组件平台、车后服务市场、数据信用链体系在内的五大产品服务体系，以及为客户提供“一站式”数字化解决方案的能力。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic3_4.jpg" alt="">
                </div>
                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_3'
    }
</script>