<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">载誉前行！禹联网络荣膺“2023中国物流创新示范企业”</div>
                <div class="article-time">2023年12月14日</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic12_1.jpg" alt="">
                </div>
                <div class="article-p">12月13日-14日，2023（第二十一届）中国物流企业家年会在西双版纳傣族自治州景洪市隆重举行。</div>
                <div class="article-p">“中国物流企业家年会”是物流行业极具权威性、影响力和高规格的行业年度盛会。本次年会由中国物流与采购联合会，云南省发展与改革委员会、西双版纳傣族自治州人民政府联合主办。来自国内外物流企业家代表、地方政府领导、专家学者近千人汇聚一堂，禹联网络应邀出席本次盛会。</div>
                <div class="article-p">作为我国物流行业极具权威性、影响力和高规格的行业年度盛会，“中国物流企业家年会”至今已经举办了二十一届。活动旨在进一步贯彻新发展理念，推进物流行业的高质量发展与物流强国建设，交流分享行业发展面临的新挑战与新机遇。</div>
                <div class="article-p">本届年会以“共建大通道·融合促发展”为主题，今年是共建“一带一路”倡议提出十周年，在“一带一路”的重要枢纽云南西双版纳召开意义重大。大会就当前新格局下加速物流企业数智化转型升级，促进现代物流提质、增效、降本，以及“双碳”背景下物流行业发展趋势等议题进行深入探讨。</div>
                <div class="article-p-title">禹联网络荣膺 “2023中国物流创新示范企业”</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic12.jpg" alt="">
                </div>
                <div class="article-p">禹联网络凭借技术与创新驱动数字货运的发展成果，成功摘得“2023中国物流创新示范企业”奖项，彰显了公司在数字货运领域不断深耕技术与服务客户的成绩与实力的累积。</div>
                <div class="article-p">2023年公司获得“国家高新技术企业”认定、“安徽省科技型中小企业“认定，公司自主研发的“禹联网络智慧货运平台”项目获得行业协会的多项荣誉，见证了禹联在数字货运领域不断提升的技术创新和研发实力，进一步提高了公司的市场竞争力和品牌价值。</div>
                <div class="article-p">未来，禹联网络坚持“诚信、奋斗、创新、卓越”的企业价值观，不断夯实技术研发水平和自主创新能力，加强人才队伍培养与建设，拓宽全场景业务服务能力，打造数字货运全产业链服务的生态体系，为行业带来价值。</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_12'
    }
</script>