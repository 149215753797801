var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"about-area2 about-area5",attrs:{"id":"title3"}},[_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"title fontsize55 wow animated fadeInDown"},[_vm._v("奖项荣誉资质")]),_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.honorList),function(item){return _c('div',{key:item.text,staticClass:"swiper-slide"},[_c('div',{staticClass:"honor-item"},[_c('div',{staticClass:"honor-item-img"},[_c('img',{attrs:{"src":_vm.imgUrl(item.img),"width":"100%","alt":""}})]),_c('p',{staticClass:"fontsize20"},[_vm._v(_vm._s(item.text))])])])}),0),_c('div',{staticClass:"swiper-button-prev"}),_c('div',{staticClass:"swiper-button-next"})])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-banner"},[_c('img',{attrs:{"src":require("../images/about/banner-2.png"),"width":"100%","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-area1",attrs:{"id":"title1"}},[_c('div',{staticClass:"container-wrapper about-area1-inner"},[_c('div',{staticClass:"about-area1-left wow animated slideInLeft"},[_c('div',{staticClass:"text1 fontsize55"},[_vm._v("关于我们")]),_c('div',{staticClass:"text2 fontsize28"},[_vm._v("禹联网络丨物流生态链数字化服务商")]),_c('div',{staticClass:"text3 fontsize20"},[_vm._v("禹联网络成立于 2020 年 6 月23日，是一家基于物联网科技和数字化园区招商联动打造的公路货运数字化承运平台。")]),_c('div',{staticClass:"text3 fontsize20"},[_vm._v("禹联网络以“让数字物流，服务美好生活”为使命，致力于成为行业先进的物流生态链数字化服务商，打造“安全、开放、赋能、共享”的综合型公路货运数字化承运平台，依托物联网技术和数字化园区优势资源，服务物流企业实现数字化转型，大大降低社会物流成本，提升物流行业运转效率，为客户、行业创造价值回报社会。")])]),_c('div',{staticClass:"about-area1-right wow animated slideInRight"},[_c('img',{attrs:{"src":require("../images/about/logo.png"),"width":"100%","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-area2",attrs:{"id":"title2"}},[_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"title fontsize55 wow animated fadeInDown"},[_vm._v("企业文化")]),_c('div',{staticClass:"about-area2-inner"},[_c('div',{staticClass:"item wow animated flipInX"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../images/about/wenhua-1.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text1 fontsize25"},[_vm._v("禹联愿景")]),_c('div',{staticClass:"text2 fontsize20"},[_vm._v("行业先进的物流生态链数字化服务商")])]),_c('div',{staticClass:"item wow animated flipInX"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../images/about/wenhua-2.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text1 fontsize25"},[_vm._v("禹联使命")]),_c('div',{staticClass:"text2 fontsize20"},[_vm._v("让数字物流，服务美好生活！")])]),_c('div',{staticClass:"item wow animated flipInX"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../images/about/wenhua-3.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text1 fontsize25"},[_vm._v("核心价值")]),_c('div',{staticClass:"text2 fontsize20"},[_vm._v("服务中小微物流及企业实现数字化转型")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-area3"},[_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"about-area3-inner"},[_c('div',{staticClass:"item wow animated flipInY"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../images/about/wenhua-4.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text1 fontsize25"},[_vm._v("经营理念")]),_c('div',{staticClass:"text2 fontsize18"},[_vm._v("安全、开放、赋能、共享")])]),_c('div',{staticClass:"item wow animated flipInY"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../images/about/wenhua-5.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text1 fontsize25"},[_vm._v("服务理念")]),_c('div',{staticClass:"text2 fontsize18"},[_vm._v("为合作伙伴创造更高价值")])]),_c('div',{staticClass:"item wow animated flipInY"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../images/about/wenhua-6.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text1 fontsize25"},[_vm._v("社会价值")]),_c('div',{staticClass:"text2 fontsize18"},[_vm._v("降低社会物流成本，提升物流行业运转效率")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-area2 about-area4"},[_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"title fontsize55 wow animated fadeInDown"},[_vm._v("禹联价值观")]),_c('div',{staticClass:"about-area2-inner about-area3-inner"},[_c('div',{staticClass:"item item1 wow animated flipInX"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../images/about/jiazhi-1.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text1 fontsize25"},[_vm._v("诚信")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"text2 fontsize18"},[_vm._v("诚实守信 行稳致远")])]),_c('div',{staticClass:"item item2 wow animated flipInX"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../images/about/jiazhi-2.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text1 fontsize25"},[_vm._v("创新")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"text2 fontsize18"},[_vm._v("创新是企业高质量发展第一动力")])]),_c('div',{staticClass:"item item3 wow animated flipInX"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../images/about/jiazhi-3.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text1 fontsize25"},[_vm._v("奋斗")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"text2 fontsize18"},[_vm._v("拼搏奋斗 敢于争先")])]),_c('div',{staticClass:"item item4 wow animated flipInX"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("../images/about/jiazhi-4.png"),"alt":"","width":"100%"}})]),_c('div',{staticClass:"text1 fontsize25"},[_vm._v("卓越")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"text2 fontsize18"},[_vm._v("追求卓越 终身成长")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-area2 about-area6"},[_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"title fontsize55 wow animated fadeInDown"},[_vm._v("企业人才观")]),_c('div',{staticClass:"title-sub fontsize24 wow animated fadeInDown"},[_vm._v("让员工与企业互相成就，共同成长")]),_c('div',{staticClass:"about-area6-inner"},[_c('div',{staticClass:"about-item wow animated slideInLeft"},[_c('img',{attrs:{"src":require("../images/about/rencai-1.png"),"alt":"","width":"100%"}}),_c('div',{staticClass:"about-item-title fontsize24"},[_vm._v("禹联团队")])]),_c('div',{staticClass:"about-item wow animated slideInRight"},[_c('img',{attrs:{"src":require("../images/about/rencai-2.png"),"alt":"","width":"100%"}}),_c('div',{staticClass:"about-item-title fontsize24"},[_vm._v("团队建设")])])]),_c('div',{staticClass:"about-area6-inner about-area6-item wow animated bounceInUp"},[_c('div',{staticClass:"about-item about-item-new"},[_c('img',{attrs:{"src":require("../images/about/rencai-3.png"),"alt":"","width":"100%"}}),_c('div',{staticClass:"about-item-title fontsize24"},[_vm._v("健身空间")])]),_c('div',{staticClass:"about-item about-item-new"},[_c('img',{attrs:{"src":require("../images/about/rencai-4.png"),"alt":"","width":"100%"}}),_c('div',{staticClass:"about-item-title fontsize24"},[_vm._v("职业培训")])]),_c('div',{staticClass:"about-item about-item-new"},[_c('img',{attrs:{"src":require("../images/about/rencai-5.png"),"alt":"","width":"100%"}}),_c('div',{staticClass:"about-item-title fontsize24"},[_vm._v("福利完善")])])])])])
}]

export { render, staticRenderFns }