<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">重磅发布！禹联网络完成1亿元Pre-A轮融资</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/outer/news1_19.jpg" alt="">
                </div>
                <div class="article-p">2024年2月3日，禹联网络在常州举办2024年会盛典期间， 董事长赵宏重磅发布了禹联网络2024全新战略。</div>
                <div class="article-p">在全新战略布局启动仪式上，董事长赵宏、总裁张国宇，CTO石迎春以及投资机构代表上台共同按下启动键，正式宣布禹联网络完成1亿元Pre-A轮融资。本轮融资主要用于扩大市场份额、拓展业务类型、提升系统研发水平、增强人才队伍建设。</div>
                <div class="article-p-title">禹联网络</div>
                <div class="article-p">禹联网络科技有限公司（简称禹联网络），由蚌埠禹投集团（国企）与禾水企业管理服务中心共同出资成立，是一家综合型公路货运承运平台。禹联网络致力于成为行业先进的物流生态链数字化服务商，打造“安全、开放、赋能、共享”的综合型公路货运数字化承运平台，依托物联网技术和数字化园区优势资源，服务物流企业实现数字化转型，大大降低社会物流成本，提升物流行业运转效率，为客户、行业创造价值回报社会。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic19_1.jpg" alt="">
                </div>
                <div class="article-p">成立三年以来，禹联网络不断夯实技术研发水平和自主创新能力，拥有一支专业素质过硬的数字化人才队伍，为公司持续高质量发展注入强劲动力。2023年公司先后获得“国家高新技术企业”认定、“安徽省科技型中小企业“认定，以及行业媒体及行业协会多项荣誉，见证了禹联在数字化货运领域不断提升的技术创新和研发实力，进一步提升了公司的市场竞争力和品牌价值。</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_19'
    }
</script>