<template>
    <component v-if="compName" :is="compName" :key="key"/>
</template>

<script>
    import Detail1_1 from './NewsDetail/Detail1_1'
    import Detail1_2 from './NewsDetail/Detail1_2'
    import Detail1_3 from './NewsDetail/Detail1_3'
    import Detail1_4 from './NewsDetail/Detail1_4'
    import Detail1_5 from './NewsDetail/Detail1_5'
    import Detail1_6 from './NewsDetail/Detail1_6'
    import Detail1_7 from './NewsDetail/Detail1_7'
    import Detail1_8 from './NewsDetail/Detail1_8'
    import Detail1_9 from './NewsDetail/Detail1_9'
    import Detail1_10 from './NewsDetail/Detail1_10'
    import Detail1_11 from './NewsDetail/Detail1_11'
    import Detail1_12 from './NewsDetail/Detail1_12'
    import Detail1_13 from './NewsDetail/Detail1_13'
    import Detail1_14 from './NewsDetail/Detail1_14'
    import Detail1_15 from './NewsDetail/Detail1_15'
    import Detail1_16 from './NewsDetail/Detail1_16'
    import Detail1_17 from './NewsDetail/Detail1_17'
    import Detail1_18 from './NewsDetail/Detail1_18'
    import Detail1_19 from './NewsDetail/Detail1_19'
    import Detail2_1 from './NewsDetail/Detail2_1'
    import Detail2_2 from './NewsDetail/Detail2_2'
    import Detail2_3 from './NewsDetail/Detail2_3'
    import Detail2_4 from './NewsDetail/Detail2_4'
    import Detail2_5 from './NewsDetail/Detail2_5'
    import Detail2_6 from './NewsDetail/Detail2_6'
    import Detail2_7 from './NewsDetail/Detail2_7'
    import Detail2_8 from './NewsDetail/Detail2_8'
    import Detail3_1 from './NewsDetail/Detail3_1'
    import Detail3_2 from './NewsDetail/Detail3_2'
    import Detail3_3 from './NewsDetail/Detail3_3'
    import Detail3_4 from './NewsDetail/Detail3_4'
    import Detail3_5 from './NewsDetail/Detail3_5'
    import Detail3_6 from './NewsDetail/Detail3_6'
    import Detail3_7 from './NewsDetail/Detail3_7'
    import Detail3_8 from './NewsDetail/Detail3_8'
    import WowMixin from "@/WowMixin";

    export default {
        name: 'NewsCenterDetail',
        mixins: [WowMixin],
        components: {
            Detail1_1,
            Detail1_2,
            Detail1_3,
            Detail1_4,
            Detail1_5,
            Detail1_6,
            Detail1_7,
            Detail1_8,
            Detail1_9,
            Detail1_10,
            Detail1_11,
            Detail1_12,
            Detail1_13,
            Detail1_14,
            Detail1_15,
            Detail1_16,
            Detail1_17,
            Detail1_18,
            Detail1_19,
            Detail2_1,
            Detail2_2,
            Detail2_3,
            Detail2_4,
            Detail2_5,
            Detail2_6,
            Detail2_7,
            Detail2_8,
            Detail3_1,
            Detail3_2,
            Detail3_3,
            Detail3_4,
            Detail3_5,
            Detail3_6,
            Detail3_7,
            Detail3_8,
        },
        data() {
            return {
                compName: '',
                key: ''
            }
        },
        created() {
            const compCompare = {
                '1_1': Detail1_1,
                '1_2': Detail1_2,
                '1_3': Detail1_3,
                '1_4': Detail1_4,
                '1_5': Detail1_5,
                '1_6': Detail1_6,
                '1_7': Detail1_7,
                '1_8': Detail1_8,
                '1_9': Detail1_9,
                '1_10': Detail1_10,
                '1_11': Detail1_11,
                '1_12': Detail1_12,
                '1_13': Detail1_13,
                '1_14': Detail1_14,
                '1_15': Detail1_15,
                '1_16': Detail1_16,
                '1_17': Detail1_17,
                '1_18': Detail1_18,
                '1_19':Detail1_19,
                '2_1': Detail2_1,
                '2_2': Detail2_2,
                '2_3': Detail2_3,
                '2_4': Detail2_4,
                '2_5': Detail2_5,
                '2_6': Detail2_6,
                '2_7': Detail2_7,
                '2_8': Detail2_8,
                '3_1': Detail3_1,
                '3_2': Detail3_2,
                '3_3': Detail3_3,
                '3_4': Detail3_4,
                '3_5': Detail3_5,
                '3_6': Detail3_6,
                '3_7': Detail3_7,
                '3_8': Detail3_8,
            }
            const id = this.$route.query?.id;
            if (!compCompare[id]) {
                alert('文章不存在');
                return
            }
            this.compName = compCompare[id];
            this.key = Date.now();
        }
    }
</script>

<style>

</style>