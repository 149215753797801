<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">再添荣誉！禹联网络获评“2022 LOG最具创新力供应链&物流科技优秀企业”</div>
                <div class="article-time"></div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p">12月初，由罗戈网·物流沙龙主办的“2022LOG最具创新力供应链&物流科技企业”评选活动正式开始。经罗戈研究专家评审，禹联网络荣获“2022 LOG最具创新力供应链&物流科技企业”，再获行业及资本圈肯定！</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic4_1.jpg" alt="">
                </div>
                <div class="article-p">本次由中国领先物流行业媒体——罗戈网/物流沙龙发起的“2022LOG年度最具创新力供应链&物流科技企业”评选，有近百家企业报名参与角逐，专家评审通过科技能力、服务能力、产品介绍、创新和领先性四个维度进行评选。</div>
                <div class="article-p">12月22日，由罗戈网举办的「数实互生·融生无界」为主题的“2022第五届中国物流科技创新企业年会暨物流资本云峰会”上，罗戈研究院长潘永刚会上公布了2022LOG年度最具创新力供应链&物流科技企业的获奖名单。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic4_2.jpg" alt="">
                </div>
                <div class="article-p">禹联网络凭借物流科技创新进步得到罗戈研究专家评审团和行业各界人士高度认可，获得“2022 LOG年度最具创新力供应链&物流科技企业”奖项。禹联网络斩获这一极具专业标准和行业代表性的奖项，也标志着企业在技术创新、产品运营、社会价值等方面得到了行业广泛认可。</div>
                <div class="article-p">禹联网络依托物联网技术和数字产业园优势资源，打造综合型公路货运承运平台，以建设数字化协同供应链为核心，通过在价值服务链、产品链、大数据信用链的综合作用下，实现全链路精细化和高效化管理，不断拓宽全场景业务服务能力，助力物流企业降本增效，并为推动物流行业数字化发展做出积极贡献。</div>
                <div class="article-p">荣誉，是实力，更是动力！未来，禹联网络将持续通过技术创新及扎实稳健的服务精神，用科技赋能伙伴，为客户创造更高的价值！</div>
                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_4'
    }
</script>