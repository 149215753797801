<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">“最美奋斗者”故事丨禹联网络董事长赵宏在“劳动创造幸福”主题论坛分享奋斗历程</div>
                <div class="article-time">2022年9月29日</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic6_1.jpg" alt="">
                </div>
                <div class="article-p">来源：蚌埠日报</div>
                <div class="article-p">9月29日,由市文明办、蚌埠日报社、市工商联共同主办,禹会区委宣传部、禹会区文明办、禹会区工商联承办的“喜迎二十大 强国复兴有我”2022年蚌埠市“劳动创造幸福”主题论坛暨“最美奋斗者”故事分享会在禹会区举行。</div>
                <div class="article-p">多位“最美奋斗者”候选人代表一起分享奋斗故事,回顾奋斗历程,探讨奋斗未来。 网络货运平台通过构建物流业务数字化，实现线下物流线上化，带动流程、调度、服务、结算等全链条数字化升级，结算规则明确，结算效率大大提高，有效降低了资金结算成本。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic6.jpg" alt="">
                </div>
                <div class="article-p">据悉,此次活动深入贯彻落实习近平新时代中国特色社会主义思想,以培育和践行社会主义核心价值观为根本,以“转作风、优环境,抓招商、推创新,增投资、建项目,惠民生、创幸福”为工作主线。通过深入挖掘和大力宣传企业界和社会组织界干事创业的先进典型及其感人事迹,培育一批既能推动蚌埠经济发展,又能履行社会责任的最美奋斗者,引导全市上下树立奋起直追的志气勇气,迎难而上,为努力建设幸福蚌埠、文明蚌埠贡献力量。</div>
                <div class="article-p">现场,来自禹会区的最美奋斗者候选人共九位代表进行分享。他们为梦想拼搏实干、用劳动创造幸福的精神,鼓舞着大家努力去追梦。</div>
                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_6'
    }
</script>