<template>
    <div class="footer-one-bg">
        <div class="container-wrapper footer-wrapper">
            <div class="footer-left wow animated fadeInRight">
                <div class="footer-left-logo"><img src="../images/logo2.png" width="100%"  alt=""></div>
                <ul class="footer-left-ul">
                    <li>关于我们</li>
                    <li>
                        <div class="footer-my footer-my-icon1"></div>
                        <img src="../images/home/footer-pop-weixin.png" alt="">
                    </li>
                    <li>
                        <div class="footer-my footer-my-icon2"></div>
                        <img src="../images/home/footer-pop-video.png" alt="">
                    </li>
                </ul>
                <ul class="footer-left-ul">
                    <li>禹联小程序</li>
                    <li>
                        <div class="footer-my footer-my-text footer-my-icon3">企业端</div>
                        <img src="../images/home/footer-pop-company.png" alt="">
                    </li>
                    <li>
                        <div class="footer-my footer-my-text footer-my-icon3">司机端</div>
                        <img src="../images/home/footer-pop-driver.png" alt="">
                    </li>
                </ul>
            </div>
            <div class="footer-center wow animated fadeInUp">
                <div class="single-wized-footer" v-for="item in menus" :key="item.path">
                    <div class="title-heading">{{item.label}}</div>
                    <ul class="list">
                        <li  v-for="sub in item.children" :key="sub.label" class="text" @click="handleScroll(item.path, sub.anchor)">{{sub.label}}</li>
                    </ul>
                </div>
            </div>
            <div class="footer-right wow animated fadeInLeft">
                <div class="single-wized-footer">
                    <div class="title-heading">联系我们</div>
                    <ul class="list">
                        <li class="text list-icon list-phone">0552-3012022</li>
                        <li class="text list-icon list-address">安徽省蚌埠市禹会区燕山路966号冠宜大厦2号楼17楼</li>
                        <li class="text list-icon" style="margin-top: 0.1rem">皖ICP备2023005002号-1</li>
                    </ul>
                    <a class="kehuduan" href="https://admin.yulianwangluo.com/" target="_blank">禹联客户端</a>
                </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                menus: [
                    {
                        label: '关于禹联',
                        path: '/about',
                        children: [
                            {label: '禹联简介', anchor: 'title1'},
                            {label: '禹联文化', anchor: 'title2'},
                            {label: '荣誉资质', anchor: 'title3'},
                        ]
                    },
                    {
                        label: '数字货运',
                        path: '/digital',
                        children: [
                            {label: '网络货运', anchor: 'title1'},
                            {label: '解决方案', anchor: 'title2'},
                            {label: '禹联生态', anchor: 'title3'},
                        ]
                    },
                    {
                        label: '新闻中心',
                        path: '/news',
                        children: [
                            {label: '企业动态', anchor: 'title1'},
                            {label: '媒体报道', anchor: 'title2'},
                            {label: '行业观察', anchor: 'title3'},
                        ]
                    },
                ]
            }
        },
        methods: {
            handleScroll(path, anchor) {
                this.$bus.$emit('menuClick', path, anchor)
            }
        }
    }
</script>

<style lang="less" scoped>
.footer-one-bg{
    background: #2d3035;
    padding: 0.3rem 0 0.5rem 0;
    .footer-wrapper{
        display: flex;
    }
    .footer-left{
        display: flex;
        flex-direction: column;
        width: 4rem;
        flex-shrink: 0;
        .footer-left-logo{
            width: 1.6rem;
        }
        .footer-left-ul{
            margin-top: 0.34rem;
            display: flex;
            align-items: center;
            background: #202327;
            height: 0.44rem;
            line-height: 0.44rem;
            border-radius: 0.22rem;
            padding: 0 0.25rem;
            li{
                position: relative;
                color: #fff;
                font-size: 0.16rem;
                white-space: nowrap;
                &:hover{
                    color: #fff;
                    img{
                        display: block;
                    }
                }
                &:hover .footer-my-text{
                    color: #0686eb;
                }
            }
            img {
                display: none;
                position: absolute;
                width: 1.1rem;
                height: 1.2rem;
                left: 50%;
                margin-left: -0.45rem;
                bottom: 0.38rem;
            }
            .footer-my{
                margin-left: 0.3rem;
            }
            .footer-my-icon1, .footer-my-icon2{
                width: 0.46rem;
                height: 0.46rem;
            }
            .footer-my-icon1{
                background: url("../images/home/icon-public.png") no-repeat center center;
            }
            li:hover .footer-my-icon1{
                background: url("../images/home/icon-public-hover.png") no-repeat center center;
            }
            .footer-my-icon2{
                background: url("../images/home/icon-video.png") no-repeat center center;
            }
            li:hover .footer-my-icon2{
                background: url("../images/home/icon-video-hover.png") no-repeat center center;
            }
            li .footer-my-icon3{
                background: url("../images/home/icon-scan.png") no-repeat left center;
                background-size: 0.35rem 0.26rem;
                padding-left: 0.35rem;
            }
            li:hover .footer-my-icon3{
                background: url("../images/home/icon-scan-hover.png") no-repeat left center;
                background-size: 0.35rem 0.26rem;
            }
        }
    }
    .footer-center{
        display: flex;
        flex-shrink: 0;
    }
    .footer-right{
        flex: 1;
        position: relative;
        .kehuduan{
            position: absolute;
            top: 0;
            right: 0;
            color: #fff;
            font-size: 0.16rem;
            background: #0686eb;
            height: 0.38rem;
            line-height: 0.38rem;
            border-radius: 0.19rem;
            padding: 0 0.22rem;
        }
    }
    .single-wized-footer{
        margin-left: 0.7rem;
        .list{
            display: flex;
            flex-direction: column;
        }
    }
     .single-wized-footer .title-heading {
        color: #999999;
        font-size: 0.18rem;
    }
     .single-wized-footer .list .text {
        margin-top: 0.23rem;
         font-size: 0.16rem;
        color: #fff;
        font-weight: 400;
         position: relative;
         cursor: pointer;
     }
     .single-wized-footer .list .text:hover {
        color: #0686eb;
    }
    .single-wized-footer .list .list-icon{
        padding-left: 0.42rem;
        line-height: 0.35rem;
        cursor: text;
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 0.35rem;
            height: 0.35rem;
        }
    }
     .single-wized-footer .list .list-phone:before {
        background: url("../images/home/icon-add-tel.png") no-repeat center center;
         background-size: 100% 100%;
    }
     .single-wized-footer .list .list-address:before {
        background: url("../images/home/icon-address.png") no-repeat center center;
         background-size: 100% 100%;
     }
}

@media only screen and (max-width: 1200px) {
    .footer-one-bg {
        .footer-left{
            width: auto;
        }
        .footer-center, .footer-right{
            margin-top: 0.5rem;
            margin-left: -0.7rem;
        }
        .footer-left .footer-left-ul{
            height: 0.5rem;
            line-height: 0.5rem;
            li .footer-my-icon3{
                padding-left: 0.6rem;
            }
        }
    }
}
</style>