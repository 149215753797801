<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">品牌见证实力！禹联网络获评运联智库“2022-2023年最具成长力企业”</div>
                <div class="article-time">2023年2月23日</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic1.jpg" alt="">
                </div>
                <div class="article-p">2月23日，由运联智库举办的“2023（第十届）趋势与预测大会”在深圳召开，行业大咖、专家学者及物流行业企业齐聚一堂，共同探讨“整个行业的未来发展趋势与机遇”。</div>
                <div class="article-p-title">禹联网络获评2022-2023年度最具成长力企业</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic1_1.jpg" alt="">
                </div>
                <div class="article-p">在这样一场备受瞩目的大会上，凭借过去一年在物流科技方面的创新进步，禹联网络斩获“2022-2023年最具成长力企业”奖项！</div>
                <div class="article-p">面对行业协会及专家学者对禹联网络公路货运数字化转型实绩的肯定，也让禹联网络有更大的信心，服务客户降本增效，为行业发展贡献一份力量！</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic1_2.jpg" alt="">
                </div>
                <div class="article-p">运联智库“趋势与预测大会”自2014年起创办至今，已连续举办了九届，每一届都是行业爆炸性的交流盛宴。大会齐聚了来自物流行业政策、技术、互联网等各个领域的大咖，多维度、多视角地探讨和解读当前物流行业的挑战与机遇。</div>
                <div class="article-p">未来很难预测，但趋势可以被感知。在新技术、新变革的驱动下，如何在逆势中提前看到并布局未来，是本届大会探讨的关键。</div>
                <div class="article-p">本次大会重点分析当下全球物流供应链安全重构的挑战与机遇，与会嘉宾纷纷从自身经历及行业现状出发，围绕“物流供应链重构”“物流数字化转型”“车队价值再创造”“平台模式如何适应新赛道”等专题开展了深入互动和交流。</div>
                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_1'
    }
</script>