<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">“第十一届中国城市物流发展年会”在杭州召开，禹联网络获评“中国城市物流发展数字化应用创新案例”</div>
                <div class="article-time">2021年8月13日</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic8_1.jpg" alt="">
                </div>
                <div class="article-p">8月13日，由中国交通运输协会主办、中国交通运输协会物流技术装备专业委员会承办“第十一届中国城市物流发展年会”在杭州召开。</div>
                <div class="article-p">会上表彰了2021年度中国物流发展创新成果，"禹联网络智运平台项目"荣获“中国城市物流发展数字化应用创新案例”。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic8_2.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic8_3.jpg" alt="">
                </div>
                <div class="article-p">本届年会以“创新驱动智慧城市物流再升级”为主题，来自国家有关部委、各级地方政府有关部门、行业协会的领导及专家，以及商贸流通企业、物流技术装备制造商、物流园区、智慧物流嘉宾代表共同出席。 </div>
                <div class="article-p">会议上，与会嘉宾围绕城市物流数字化、城市绿色物流、生鲜电商与冷链物流等专题论坛展开深入互动和交流。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic8_4.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic8_5.jpg" alt="">
                </div>
                <div class="article-p">本次大会表彰了2021年中国物流发展创新成果，“禹联网络智运平台项目”荣获“中国城市物流发展数字化应用创新案例”。  网络货运平台通过构建物流业务数字化，实现线下物流线上化，带动流程、调度、服务、结算等全链条数字化升级，结算规则明确，结算效率大大提高，有效降低了资金结算成本。</div>
                <div class="article-p">面对物流企业多元化模式、场景复杂化特点，“禹联网络智运平台项目"从客户需求出发，依托物联网模块、软件及系统开发等一系列产品与技术服务，基于客户具体场景提供一对一定制的数字化服务解决方案，有针对性的帮客户解决作业效率和成本痛点，提升精细化管理和智能化管理能力，服务合作企业数字化转型。</div>
                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_8'
    }
</script>