<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 媒体报道 &gt; 详情</div>
                <div class="article-title">“筑巢引凤”结硕果 安徽蚌埠招商引资汇聚发展新动能</div>
                <div class="article-time">来源：人民资讯 2021-07-15</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p">近年来，安徽省蚌埠市主动抢抓长三角一体化发展等国家战略机遇，通过不断加大对特色产业、重点企业的支持力度，特别是对骨干产业和重点企业的支持力度，力争吸引更多优质企业落户蚌埠。其中，针对初创期的科技型企业更加优待，为有创新性、市场前景好的企业保驾护航。</div>
                <div class="article-p">据了解，2021年，蚌埠市起草了《关于鼓励支持异地商会参与蚌埠经济发展的意见》，进一步加强与协会、商会紧密联系。此外，蚌埠市还编制了产业招商路线图，建立了招商目标企业库，制订“双招双引”方案，加大重点项目跟踪对接，全市项目不断集聚，助力打造产业创新高地。</div>
                <div class="article-p">禹联网络科技有限公司由蚌埠禹投集团与禾水企业管理服务中心于2020年7月共同出资成立，主要从事基于物联网科技和数字化园区招商联动打造的平台型物流企业。禹联网络科技相关负责人表示：“在公司成立一周年之际，单月营收就突破2个亿，这离不开当地政府良好的政策支持。而企业最初之所以选择在蚌埠落户，最重要的是蚌埠的招商投资环境很好，适合企业长期发展。”</div>
                <div class="article-p">数据显示，2020年1至10月份，蚌埠市禹会区完成市外亿元以上项目到位资金85.71亿元，引进亿元以上新项目23个，招商引资成效显著。今年一季度，该市达成合作意向企业超百家，新签约亿元以上项目51个，总投资289.71亿元。今年1至5月，全市新签约亿元以上项目85个，同比增长39.3%，协议总投资627.5亿元。</div>
                <div class="article-p"></div>
                <div class="article-p"></div>
                <div class="article-p"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail2_7'
    }
</script>