<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 媒体报道 &gt; 详情</div>
                <div class="article-title">持续优化营商环境 实现企业追求与政府支持“同频共振”</div>
                <div class="article-time">来源：人民网  2022年07月28日</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news2_pic6_1.jpg" alt="">
                </div>
                <div class="article-p">（转载自蚌埠日报）招商引资、招才引智是经济工作的“第一抓手”，是推动高质量发展的重要支撑。安徽省蚌埠市禹会区以真心真情的态度优化营商环境，进一步加强与企业互动交流，实现企业追求与政府支持的“同频共振”，以一流营商环境助力高质量发展。</div>
                <div class="article-p">禹联网络科技有限公司是一家主要从事基于物联网科技和数字化园区招商联动打造的平台型物流企业，由蚌埠禹投集团与禾水企业管理服务中心于2020年6月共同出资成立。“当初落户禹会区也是看中了这里良好的营商环境，政府主动靠前服务，还有一系列政策可以享受。”禹联网络科技有限公司董事长赵宏表示，眼下企业落户刚好两周年，优异的业绩也更加印证了当时的选择。</div>
                <div class="article-p">据禹联网络科技有限公司总经理张国宇介绍，在当地营商环境助推的企业高质量发展大潮中，公司不仅发展好而且发展快，目前公司业务实现了全国覆盖，2022年公司单月营收突破3亿元，同比去年翻了2倍，禹联网络正朝着行业先进的物流生态链数字化服务商目标稳步迈进。</div>
                <div class="article-p">既当“管理员”，又当“服务员”，是禹会区助企腾飞的发展“密码”。禹会区作为老工业区，工业基础雄厚，又手握中国(安徽)自由贸易试验区蚌埠片区、国家新型工业化示范基地、全省首批先进制造业和现代服务业融合发展试点单位等平台优势，目前正全力以赴推动“双招双引”和工业发展提质增效。本着坚持发展主导产业、集约高效利用土地资源、利用资本引入投资的原则，努力建活机制、建强园区、建优环境，取得有效进展。上半年，禹会区新签约亿元以上项目18个，计划总投资72.1亿元，其中工业项目17个，15亿元以上项目2个，10亿元以上项目1个，利用省外到位资金48.00亿元。</div>
                <div class="article-p">数字化背景下，针对辖区内一些优秀的互联网物流企业，禹会区对其进行发展指导外，还专门性出台了系列优惠政策，助力企业做大做强。如协助注册项目企业，办理道路运输经营许可证(具有普通货物、集装箱运输、大件运输等经营许可)等相关资质和手续；协助企业协调与当地银行的关系，确保企业对大批量个体司机的正常结算时银行业务的办理；积极协助企业办理牵引车和半挂车的上牌及营运证的办理和年审等，并给予协调办理异地上牌并开辟绿色通道等相关业务。</div>
                <div class="article-p">“主动深入企业，一心一意为企业做好服务，优质高效解决企业自身发展中的问题。及时了解企业生产经营情况，为企业排忧解难。”禹会区投资促进局局长宋焱介绍，通过不定期召开企业座谈会，面对面和企业家交流谈心，以企业的满意度来评判服务能力强不强、效率高不高。今后，禹会区将把持续优化园区营商环境作为推动经济高质量发展的重要抓手，营造“亲商、安商、富商”的浓厚氛围。围绕“数字禹会”建设，探索平台经济、数字经济等新型互联网+产业发展路径，充分发挥优质互联网物流企业的作用，并争取引进一批有带动效应的高质量互联网+企业入驻，为全区经济社会发展不断注入新动能。</div>
                <div class="article-p"></div>
                <div class="article-source"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail2_6'
    }
</script>