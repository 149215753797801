<template>
    <div>
        <!-- banner图 -->
        <div class="newsCenter-banner">
            <img src="../images/news-center/banner.png" width="100%" alt="">
        </div>

        <!-- 新闻中心 -->
        <div class="news-tab">
            <ul class="nav-tabs wow animated fadeInDown">
                <li class="nav-item fontsize32" :class="{'active': activeTab === item.id}"
                    v-for="item in tabs" :key="item.id" @click="toggleTab(item)">
                    {{item.title}}
                </li>
            </ul>
        </div>
        <div class="container-wrapper">
            <div class="home-news news-center-wrapper">
                <div class="home-news-item wow animated flipInX" v-for="item in activeChildren" :key="item.id" @click="toDetail(item)">
                    <div class="thumbnail">
                        <img :src="imgUrl(item.img)" width="100%" alt="">
                    </div>
                    <div class="body">
                        <div class="title fontsize24">{{item.title}}</div>
                        <div class="content">
                            <span></span>
                            <router-link :to="`/news/detail?id=${item.id}`" class="rts-read-more fontsize20">详情</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WowMixin from "@/WowMixin";

    export default {
        name: 'NewsCenter',
        mixins: [WowMixin],
        data() {
            return {
                activeTab: this.$route.query.id || 'title1',
                tabs: [
                    {
                        title: '禹联动态', id: 'title1',
                        children: [
                            // {id: '1_19', img: 'news1_19', date: '', title: '重磅发布！禹联网络完成1亿元Pre-A轮融资'},
                            {id: '1_12', img: 'news1_12', date: '', title: '载誉前行！禹联网络荣膺“2023中国物流创新示范企业”'},
                            {id: '1_13', img: 'news1_13', date: '', title: '新里程碑！祝贺禹联网络荣获“国家高新技术企业”认定'},
                            {id: '1_14', img: 'news1_14', date: '', title: '禹联网络荣获中国数字货运平台十佳推荐金牌服务商'},
                            {id: '1_17', img: 'news1_17', date: '', title: '禹联再添荣誉！2024趋势与预测大会传喜讯！'},
                            {id: '1_16', img: 'news1_16', date: '', title: '禹联网络荣获“安徽省科技型中小企业”认定'},
                            {id: '1_15', img: 'news1_15', date: '', title: '禹联网络荣获“2023年度优秀智慧物流信息平台应用案例”'},
                            {id: '1_18', img: 'news1_18', date: '', title: '“情暖重阳 敬老爱老”—禹联网络养老院献爱心'},
                            {id: '1_1', img: 'news1_1', date: '', title: '品牌见证实力！禹联网络获评运联智库“2022-2023年最具成长力企业”'},
                            {id: '1_2', img: 'news1_2', date: '', title: '禹联网络与国都证券举行战略合作签约仪式！'},
                            {id: '1_3', img: 'news1_3', date: '', title: '禹联网络入选《2023中国供应链物流创新科技报告》'},
                            {id: '1_4', img: 'news1_4', date: '', title: '禹联网络获评“2022 LOG最具创新力供应链&物流科技优秀企业”'},
                            {id: '1_5', img: 'news1_5', date: '', title: '创新成果获肯定！禹联网络智慧货运平台获评 “2022年度网络货运平台应用优秀案例”'},
                            {id: '1_6', img: 'news1_6', date: '', title: '禹联网络董事长赵宏在“劳动创造幸福”主题论坛分享奋斗历程'},
                            {id: '1_7', img: 'news1_7', date: '', title: '中物联平台分会秘书长晏庆华莅临禹联网络考察交流'},
                            {id: '1_8', img: 'news1_8', date: '', title: '“第十一届中国城市物流发展年会”在杭州召开，禹联网络斩获荣誉！'},
                            {id: '1_9', img: 'news1_9', date: '', title: '禹联网络上榜“2021中国交通运输新业态百强企业”'},
                            {id: '1_10', img: 'news1_10', date: '', title: '禹联网络获评“2021年度物流信息化优秀推荐品牌”'},
                            // {id: '1_11', img: 'news1_11', date: '', title: '品牌升级！禹联网络新LOGO正式上线'},
                        ]
                    },
                    {
                        title: '媒体报道', id: 'title2',
                        children: [
                            {id: '2_1', img: 'news2_1', date: '', title: '“禹”行业同存，“联”科技共创'},
                            {id: '2_2', img: 'news2_2', date: '', title: '禹联网络创始人赵宏：面对高峰低谷，保持坚韧和乐观，做长期主义者'},
                            {id: '2_3', img: 'news2_3', date: '', title: '网络货运关键一年：禹联网络助力传统行业快速向数字化转型'},
                            {id: '2_8', img: 'news2_8', date: '', title: '祝贺！禹联网络董事长赵宏荣获“最美奋斗者”称号'},
                            {id: '2_4', img: 'news2_4', date: '', title: '网络货运发展进入“深水区”，禹联网络数字化深耕垂直场景突围之法'},
                            {id: '2_5', img: 'news2_5', date: '', title: '10吨新鲜蔬菜现采直发，禹联网络爱心捐赠驰援上海'},
                            {id: '2_6', img: 'news2_6', date: '', title: '持续优化营商环境 实现企业追求与政府支持“同频共振”'},
                            {id: '2_7', img: 'news2_7', date: '', title: '“筑巢引凤”结硕果 安徽蚌埠招商引资汇聚发展新动能'},
                        ]
                    },
                    {
                        title: '行业观察', id: 'title3',
                        children: [
                            {id: '3_1', img: 'news3_1', date: '', title: '2023 中国供应链物流创新科技报告'},
                            {id: '3_2', img: 'news3_2', date: '', title: '禹联观察|卡友视角分析，网络货运平台有哪些优势？'},
                            {id: '3_3', img: 'news3_3', date: '', title: '禹联观察丨数读公路货运行业痛点，网络货运降本增效见实效！'},
                            {id: '3_7', img: 'news3_7', date: '', title: '禹联观察 | “碳”寻未来！ ESG引领绿色物流新发展'},
                            {id: '3_8', img: 'news3_8', date: '', title: '禹联观察丨重塑物流生态，AI大模型加速落地!'},
                            {id: '3_4', img: 'news3_4', date: '', title: '政策护航！网络货运核心价值有多强'},
                            {id: '3_5', img: 'news3_5', date: '', title: '网络货运丨物流企业把握数字化转型的好机遇'},
                            {id: '3_6', img: 'news3_6', date: '', title: '禹联观察丨好风向！一图带你详细了解“平台经济”'},
                        ]
                    }
                ]
            }
        },
        computed: {
            activeChildren() {
                const findItem = this.tabs.find(item => item.id === this.activeTab);
                if (!findItem) {
                    // this.activeTab = this.tabs[0].id;
                    return this.tabs[0].children;
                }
                return findItem.children;
            }
        },
        methods: {
            imgUrl(img) {
                return require(`../images/news-center/outer/${img}.jpg`);
            },
            toggleTab(item) {
                this.activeTab = item.id;
            },
            toDetail(item){
               this.$router.push(`/news/detail?id=${item.id}`)
            }
        }
    }
</script>