var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"contact-form-wrapper",attrs:{"id":"title1"}},[_c('div',{staticClass:"container-wrapper network"},[_c('div',{staticClass:"title1 fontsize55 wow animated fadeInDown"},[_vm._v("禹联网络智慧货运平台")]),_vm._m(1),_c('div',{staticClass:"form-wrapper wow animated bounce"},[_c('form',{attrs:{"id":"contact-form","action":"https://formspree.io/f/mzbqqqzl","method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_vm._m(2),_vm._m(3),_vm._m(4),_c('button',{staticClass:"fontsize30 rts-btn",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.formBtnText))])]),_c('div',{staticClass:"form-wrapper-tips fontsize24"},[_vm._v("请留下您的联系方式，我们将尽快与您联络")])])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-banner"},[_c('img',{attrs:{"src":require("../images/contactus/banner.png"),"width":"100%","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title2 fontsize28 wow animated fadeInDown"},[_c('div',[_vm._v("服务物流企业数字化转型 专业运营团队一站式解决方案")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name-email"},[_c('div',{staticClass:"name-email-item"},[_c('div',{staticClass:"fontsize24"},[_vm._v("企业名称"),_c('span',[_vm._v("*")])]),_c('input',{attrs:{"type":"text","name":"company","placeholder":"请输入您的企业名称","required":""}})]),_c('div',{staticClass:"name-email-item"},[_c('div',{staticClass:"fontsize24"},[_vm._v("姓名"),_c('span',[_vm._v("*")])]),_c('input',{attrs:{"type":"text","name":"name","placeholder":"请输入您的姓名","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name-email"},[_c('div',{staticClass:"name-email-item"},[_c('div',{staticClass:"fontsize24"},[_vm._v("联系电话"),_c('span',[_vm._v("*")])]),_c('input',{attrs:{"type":"tel","name":"phone","placeholder":"请输入您的手机号码","required":""}})]),_c('div',{staticClass:"name-email-item"},[_c('div',{staticClass:"fontsize24"},[_vm._v("地址"),_c('span',[_vm._v("*")])]),_c('input',{attrs:{"type":"text","name":"address","placeholder":"请输入您所在区域","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"name-email"},[_c('div',{staticClass:"name-email-item"},[_c('div',{staticClass:"fontsize24"},[_vm._v("如果你有想了解的信息或疑问，欢迎留言！")]),_c('textarea',{attrs:{"placeholder":"请陈述您的需求","name":"message"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-form-wrapper contact-map",attrs:{"id":"title2"}},[_c('div',{staticClass:"container-wrapper network"},[_c('div',{staticClass:"title1 fontsize55 wow animated fadeInDown"},[_vm._v("联系我们")]),_c('div',{staticClass:"title2 fontsize28 wow animated fadeInDown"},[_c('div',[_vm._v("服务数字转型 业务覆盖全国")])]),_c('div',{staticClass:"map-wrapper"},[_c('img',{attrs:{"src":require("../images/contactus/map.jpg"),"alt":"","width":"100%"}}),_c('div',{staticClass:"map-pop wow animated bounceIn"},[_c('img',{attrs:{"src":require("../images/contactus/map-pop.png"),"alt":"","width":"100%"}})])])])])
}]

export { render, staticRenderFns }