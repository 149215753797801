import { render, staticRenderFns } from "./Detail1_9.vue?vue&type=template&id=abb0244a&"
import script from "./Detail1_9.vue?vue&type=script&lang=js&"
export * from "./Detail1_9.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports