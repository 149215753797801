<template>
    <div class="main-header">
        <div class="container-wrapper header-container wow animated fadeInDown">
            <div class="left-logo">
                <img src="../images/logo.png" width="100%" alt="">
            </div>
            <div class="right-menu">
                <div class="men-item" v-for="item in menus" :key="item.path">
                    <router-link :to="item.path" v-if="!item.isExternal">{{item.label}}</router-link>
                    <a :href="item.path" target="_blank" v-else>{{item.label}}</a>
                    <ul class="sub-menu" v-if="item.children.length > 0">
                        <li v-for="sub in item.children" :key="sub.label" @click="handleScroll(item, sub.anchor, sub.path, sub.type)">{{sub.label}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                menus: [
                    {
                        label: '首页',
                        path: '/home',
                        children: []
                    },
                    {
                        label: '关于禹联',
                        path: '/about',
                        children: [
                            {label: '禹联简介', anchor: 'title1'},
                            {label: '禹联文化', anchor: 'title2'},
                            {label: '荣誉资质', anchor: 'title3'},
                        ]
                    },
                    {
                        label: '数字货运',
                        path: '/digital',
                        children: [
                            {label: '网络货运', anchor: 'title1'},
                            {label: '解决方案', anchor: 'title2'},
                            {label: '禹联生态', anchor: 'title3'},
                        ]
                    },
                    {
                        label: '新闻中心',
                        path: '/news',
                        children: [
                            {label: '企业动态', anchor: 'title1'},
                            {label: '媒体报道', anchor: 'title2'},
                            {label: '行业观察', anchor: 'title3'},
                        ]
                    },
                    {
                        label: '联系我们',
                        path: '/contact',
                        children: [
                            {label: '业务咨询', anchor: 'title1'},
                            {label: '联系方式', anchor: 'title2'},
                        ]
                    },
                    {
                        label: '开放平台',
                        isExternal: true,
                        path: 'https://www.showdoc.cc/2361850006810894',
                        children: [
                            {label: '开发文档', anchor: 'title1', type: 'link', path: 'https://www.showdoc.cc/2361850006810894'},
                            {label: 'SDK下载', anchor: 'title2', type: 'download', path: 'https://yulian-dev.obs.cn-east-3.myhuaweicloud.com/task/yulian-open-sdk-v1.0/1697509801478/yulian-open-sdk-java.zip?AccessKeyId=JD0VDED68F5CHOKWUTEX&Expires=2012871463&Signature=7VLkvFOlSTlg8ZJHfLCGjX/h14w%3D'},
                        ]
                    },
                ]
            }
        },
        methods: {
            handleScroll({path, isExternal}, anchor, subPath, type) {
                this.$bus.$emit('menuClick', path, anchor, isExternal, subPath, type)
            }
        }
    }
</script>

<style lang="less" scoped>
    .header-container{
        display: flex;
        align-content: stretch;
        height: 1.1rem;
        position: relative;
        z-index: 10;
    }
    .left-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.58rem;
    }
    .right-menu{
        flex: 1;
        display: flex;
        align-content: center;
        justify-content: flex-end;
    }
    .men-item{
        width: 1.1rem;
        margin-left: 0.24rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            font-size: 0.2rem;
            color: #666666;
            /*font-weight: bold;*/
            &.router-link-active{
                color: #0686eb;
                &:after{
                    content: "";
                    position: absolute;
                    top: 1.05rem;
                    left: 0;
                    right: 0;
                    height: 0.05rem;
                    background: linear-gradient(to right, #40a2f0, #0c4a9f);
                }
            }
        }
        &:hover .sub-menu{
            display: block;
        }
        .sub-menu{
            display: none;
            position: absolute;
            top: 1.1rem;
            left: 0;
            right: 0;
            text-align: center;
            background: #fff;
            color: #666666;
            font-size: 0.2rem;
            padding-bottom: 0.2rem;
            li{
                margin-top: 0.2rem;
                cursor: pointer;
                &:hover{
                    color: #0686eb;
                }
            }
        }
    }
    @media only screen and (max-width: 1200px) {
        .left-logo{
            margin-left: 0.2rem;
        }
        .men-item{
            margin-left: 0;
            width: auto;
            padding: 0 0.1rem;
            &:hover .sub-menu{
                display: none;
            }
        }
    }
</style>