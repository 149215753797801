<template>
    <div>
        <!-- banner图 -->
        <div class="about-banner">
            <img src="../images/about/banner-2.png" width="100%" alt="">
        </div>

        <!--关于我们-->
        <div class="about-area1" id="title1">
            <div class="container-wrapper about-area1-inner">
                <div class="about-area1-left wow animated slideInLeft">
                    <div class="text1 fontsize55">关于我们</div>
                    <div class="text2 fontsize28">禹联网络丨物流生态链数字化服务商</div>
                    <div class="text3 fontsize20">禹联网络成立于 2020 年 6 月23日，是一家基于物联网科技和数字化园区招商联动打造的公路货运数字化承运平台。</div>
                    <div class="text3 fontsize20">禹联网络以“让数字物流，服务美好生活”为使命，致力于成为行业先进的物流生态链数字化服务商，打造“安全、开放、赋能、共享”的综合型公路货运数字化承运平台，依托物联网技术和数字化园区优势资源，服务物流企业实现数字化转型，大大降低社会物流成本，提升物流行业运转效率，为客户、行业创造价值回报社会。</div>
                </div>
                <div class="about-area1-right wow animated slideInRight">
                    <img src="../images/about/logo.png" width="100%" alt="">
                </div>
            </div>
        </div>

        <!--企业文化-->
        <div class="about-area2" id="title2">
            <div class="container-wrapper">
                <div class="title fontsize55 wow animated fadeInDown">企业文化</div>
                <div class="about-area2-inner">
                    <div class="item wow animated flipInX">
                        <div class="img">
                            <img src="../images/about/wenhua-1.png" alt="" width="100%">
                        </div>
                        <div class="text1 fontsize25">禹联愿景</div>
                        <div class="text2 fontsize20">行业先进的物流生态链数字化服务商</div>
                    </div>
                    <div class="item wow animated flipInX">
                        <div class="img">
                            <img src="../images/about/wenhua-2.png" alt="" width="100%">
                        </div>
                        <div class="text1 fontsize25">禹联使命</div>
                        <div class="text2 fontsize20">让数字物流，服务美好生活！</div>
                    </div>
                    <div class="item wow animated flipInX">
                        <div class="img">
                            <img src="../images/about/wenhua-3.png" alt="" width="100%">
                        </div>
                        <div class="text1 fontsize25">核心价值</div>
                        <div class="text2 fontsize20">服务中小微物流及企业实现数字化转型</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="about-area3">
            <div class="container-wrapper">
                <div class="about-area3-inner">
                    <div class="item wow animated flipInY">
                        <div class="img">
                            <img src="../images/about/wenhua-4.png" alt="" width="100%">
                        </div>
                        <div class="text1 fontsize25">经营理念</div>
                        <div class="text2 fontsize18">安全、开放、赋能、共享</div>
                    </div>
                    <div class="item wow animated flipInY">
                        <div class="img">
                            <img src="../images/about/wenhua-5.png" alt="" width="100%">
                        </div>
                        <div class="text1 fontsize25">服务理念</div>
                        <div class="text2 fontsize18">为合作伙伴创造更高价值</div>
                    </div>
                    <div class="item wow animated flipInY">
                        <div class="img">
                            <img src="../images/about/wenhua-6.png" alt="" width="100%">
                        </div>
                        <div class="text1 fontsize25">社会价值</div>
                        <div class="text2 fontsize18">降低社会物流成本，提升物流行业运转效率</div>
                    </div>
                </div>
            </div>
        </div>

        <!--禹联价值观-->
        <div class="about-area2 about-area4">
            <div class="container-wrapper">
                <div class="title fontsize55 wow animated fadeInDown">禹联价值观</div>
                <div class="about-area2-inner about-area3-inner">
                    <div class="item item1 wow animated flipInX">
                        <div class="img">
                            <img src="../images/about/jiazhi-1.png" alt="" width="100%">
                        </div>
                        <div class="text1 fontsize25">诚信</div>
                        <div class="line"></div>
                        <div class="text2 fontsize18">诚实守信 行稳致远</div>
                    </div>
                    <div class="item item2 wow animated flipInX">
                        <div class="img">
                            <img src="../images/about/jiazhi-2.png" alt="" width="100%">
                        </div>
                        <div class="text1 fontsize25">创新</div>
                        <div class="line"></div>
                        <div class="text2 fontsize18">创新是企业高质量发展第一动力</div>
                    </div>
                    <div class="item item3 wow animated flipInX">
                        <div class="img">
                            <img src="../images/about/jiazhi-3.png" alt="" width="100%">
                        </div>
                        <div class="text1 fontsize25">奋斗</div>
                        <div class="line"></div>
                        <div class="text2 fontsize18">拼搏奋斗 敢于争先</div>
                    </div>
                    <div class="item item4 wow animated flipInX">
                        <div class="img">
                            <img src="../images/about/jiazhi-4.png" alt="" width="100%">
                        </div>
                        <div class="text1 fontsize25">卓越</div>
                        <div class="line"></div>
                        <div class="text2 fontsize18">追求卓越 终身成长</div>
                    </div>
                </div>
            </div>
        </div>

        <!--奖项荣誉资质-->
        <div class="about-area2 about-area5" id="title3">
            <div class="container-wrapper">
                <div class="title fontsize55 wow animated fadeInDown">奖项荣誉资质</div>
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="item in honorList" :key="item.text">
                            <div class="honor-item">
                                <div class="honor-item-img">
                                    <img :src="imgUrl(item.img)" width="100%" alt="">
                                </div>
                                <p class="fontsize20">{{item.text}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                </div>
            </div>
        </div>

        <!--企业人才观-->
        <div class="about-area2 about-area6">
            <div class="container-wrapper">
                <div class="title fontsize55 wow animated fadeInDown">企业人才观</div>
                <div class="title-sub fontsize24 wow animated fadeInDown">让员工与企业互相成就，共同成长</div>
                <div class="about-area6-inner">
                    <div class="about-item wow animated slideInLeft">
                        <img src="../images/about/rencai-1.png" alt="" width="100%" >
                        <div class="about-item-title fontsize24">禹联团队</div>
                    </div>
                    <div class="about-item wow animated slideInRight">
                        <img src="../images/about/rencai-2.png" alt="" width="100%" >
                        <div class="about-item-title fontsize24">团队建设</div>
                    </div>
                </div>
                <div class="about-area6-inner about-area6-item wow animated bounceInUp">
                    <div class="about-item about-item-new">
                        <img src="../images/about/rencai-3.png" alt="" width="100%" >
                        <div class="about-item-title fontsize24">健身空间</div>
                    </div>
                    <div class="about-item about-item-new">
                        <img src="../images/about/rencai-4.png" alt="" width="100%" >
                        <div class="about-item-title fontsize24">职业培训</div>
                    </div>
                    <div class="about-item about-item-new">
                        <img src="../images/about/rencai-5.png" alt="" width="100%" >
                        <div class="about-item-title fontsize24">福利完善</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper from 'swiper';
    import {isMobile} from "@/utils";
    import WowMixin from "@/WowMixin";

    export default {
        name:'AboutPage',
        mixins: [WowMixin],
        data() {
            return {
                honorList: [
                    {text: '（第二十一届）中国物流企业家年会“2023中国物流创新示范企业”', img: 'honor_14'},
                    {text: '荣获“国家高新技术企业”认定', img: 'honor_16'},
                    {text: '运联智库2024趋势与预测大会“物流数字化创新投资潜力企业“', img: 'honor_17'},
                    {text: '中交协&人民交通杂志“中国数字货运平台十佳推荐金牌服务商“', img: 'honor_13'},
                    {text: '罗戈网·物流沙龙2022 LOG最具创新力供应链&物流科技企业', img: 'honor_6'},
                    {text: '运联智库“2022-2023年最具成长力企业-业务增速领跑者”', img: 'honor_7'},
                    {text: '中交协物流装备委“中国城市物流发展数字化应用创新案例”', img: 'honor_8'},
                    {text: '中交协物流装备委“2021年度物流数字化先行典范企业”', img: 'honor_9'},
                    {text: '中国交通运输协会智慧物流专业委员会常务理事会员单位', img: 'honor_10'},
                    {text: '获得AAA级企业信用等级认证', img: 'honor_11'},
                    {text: '中国物流与联合会物流信息服务平台会员单位', img: 'honor_1'},
                    {text: '中交协智慧物流委“2021年度物流信息化优秀推荐品牌”', img: 'honor_2'},
                    {text: '人民交通杂志“2021交通运输新业态百强企业”', img: 'honor_3'},
                    {text: '第十四届中国物流与供应链信息化大会“ 2022年度网络货运平台应用优秀案例”', img: 'honor_4'},
                    {text: '入选罗⼽研究《2023中国供应链物流创新科技报告》', img: 'honor_5'},
                    {text: '中交协智慧委“2023年度优秀智慧物流信息平台应用案例”', img: 'honor_15'},
                    {text: '荣获“安徽省科技型中小企业”认定', img: 'honor_12'},
                ]
            }
        },
        mounted() {
            this.$nextTick((() => {
                setTimeout(() => {
                    new Swiper('.swiper-container', {
                        watchSlidesProgress: true,
                        slidesPerView:  'auto',
                        centeredSlides: true,
                        loop: true,
                        loopedSlides: 5,
                        autoplay: false,
                        slideToClickedSlide: true,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        on: {
                            progress: function(progress) {
                                for (let i = 0; i < this.slides.length; i++) {
                                    let slide = this.slides.eq(i);
                                    let slideProgress = this.slides[i].progress;
                                    let modify = 1;
                                    if (Math.abs(slideProgress) > 1) {
                                        modify = (Math.abs(slideProgress) - 1) * 0.4 + 1;
                                    }
                                    let bodyWidth = document.body.clientWidth; // 当前窗口的宽度
                                    let translate = slideProgress * modify * bodyWidth * 80 /(isMobile() ? 1300 : 1920)  + 'px';
                                    let scale = 1 - Math.abs(slideProgress) / 5;
                                    let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                                    slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                                    slide.css('zIndex', zIndex);
                                    slide.css('opacity', 1);
                                    if (Math.abs(slideProgress) > 3) {
                                        slide.css('opacity', 0);
                                    }
                                }
                            },
                            setTransition: function(transition) {
                                for (let i = 0; i < this.slides.length; i++) {
                                    let slide = this.slides.eq(i)
                                    slide.transition(transition);
                                }
                            }
                        }
                    })
                }, 500)
            }))
        },
        methods: {
            imgUrl(img){
                return require('@/images/about/'+ img + '.png')
            }
        }
    }
</script>

<style>

</style>