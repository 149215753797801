import {WOW} from "wowjs";

export default {
    mounted() {
        this.$nextTick(() => {
            this.getWow().init()
        })
    },
    methods: {
        getWow() {
            return new WOW({
                boxClass: 'wow',
                animateClass: 'animated',
                offset: 50,
                mobile: true,
                live: true,
            });
        }
    }
}