<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">品牌升级！禹联网络新LOGO正式上线</div>
                <div class="article-time"> </div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic11_1.jpg" alt="">
                </div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic11_2.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_11'
    }
</script>