<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">禹联网络荣获“2023年度优秀智慧物流信息平台应用案例”</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic15_1.jpg" alt="">
                </div>
                <div class="article-p">2024年伊始，禹联网络迎来了好消息！近日，中国交通运输协会智慧物流专业委员会公布了“2023中国智慧物流优秀案例”入选名单，禹联网络荣获2023年度优秀智慧物流信息平台应用案例！</div>
                <div class="article-p">中国交通运输协会智慧物流专业委员会于2023年5月印发《关于申报“2023中国智慧物流优秀案例”的通知》（中交协智委字（2023）2号）。本次优秀案例征集活动重点围绕物流信息平台、智慧供应链、物流信息化应用、绿色共享物流、智慧物流车辆装备，以及城市共同配送六个方向，全国共28个优秀案例入选《报告》。</div>
                <div class="article-p">本次优秀案例征集活动中，【优秀智慧物流信息平台应用案例】共13个项目入选，禹联网络跻身其中，荣获“2023年度优秀智慧物流信息平台应用案例”。同时，禹联网络智慧物流货运平台项目入编《2023中国智慧物流发展报告》，以下简称《报告》。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic15_2.jpg" alt="">
                </div>
                <div class="article-p">继往开来，续写荣誉。刚刚过去的2023年底，禹联网络在2023（第二十一届）中国物流企业家年会上，荣膺“2023中国物流创新示范企业”。</div>
                <div class="article-p">2024年伊始，禹联网络荣获“2023年度优秀智慧物流信息平台应用案例”，充分体现了行业与专家对禹联网络推动物流信息化建设以及禹联研发创新实力的高度认可。</div>
                <div class="article-p">相信2024年，禹联网络向高质量稳定发展，迈出更加坚定的步伐！</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_15'
    }
</script>