<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 媒体报道 &gt; 详情</div>
                <div class="article-title">10吨新鲜蔬菜现采直发，禹联网络爱心捐赠驰援上海</div>
                <div class="article-time">来源：蚌埠日报 2022-04-20  原标题：我市爱心企业捐赠10吨新鲜蔬菜驰援上海</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p">“你看这些蔬菜多新鲜，都是从地里刚刚采摘的，待会莴苣到了先打底装车，等晚上天气凉了就发车……”4月17日，淮上区梅桥镇大岗村，禹联网络科技有限公司董事长、国沃能源联合创始人赵宏对记者说。据悉，4月18日上午，由该公司捐助首批10吨蔬菜及部分生活物资，已送达位于浦东新区的上海电机学院。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news2_pic5.png" alt="">
                </div>
                <div class="article-p">志愿者查验支援上海的蔬菜</div>
                <div class="article-p">17日下午3时许，记者来到装车现场，看到赵宏正带着员工在货车车身张贴印有“抗击疫情”、“同心守沪”等字样的横幅，多辆装着蔬菜的电动三轮车陆续驶来，“我原来家在上海，现在蚌埠创业，不少客户也在上海，所以对那边的疫情特别关注，特别希望能通过自己资源、能力去支持上海抗疫。”他说，4月初，在联系上海市红十字会和宝山工业园区管委会的过程中，了解到浦东部分高校因为疫情导致物资紧缺，经多方确认后，最终定向支援上海电机学院。</div>
                <div class="article-p">据禹联网络科技有限公司总经理张国宇介绍，该公司的爱心捐赠得到相关部门的大力支持。禹会区商务外事局及区农业农村水利局及时协调，出具捐赠等相关证明，并由长青乡涂山村、梅桥镇大岗村等提供了新鲜蔬菜货源。</div>
                <div class="article-p">90后沈军陈是这次运送物资的司机，“我是禹联网络平台的司机，知道招募信息后，第一时间就报名了，这次去上海全程不能离车，回蚌埠还要进行隔离。不过，只要能支援上海抗疫，我不后悔。”</div>
                <div class="article-p"></div>
                <div class="article-p"></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail2_5'
    }
</script>