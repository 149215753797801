<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">禹联要闻丨中物联平台分会秘书长晏庆华莅临禹联网络考察交流</div>
                <div class="article-time">2022年8月31日</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic7.jpg" alt="">
                </div>
                <div class="article-p">8月31日下午，中国物流与采购联合会网络事业部主任、物流信息服务平台秘书长晏庆华莅临禹联网络科技有限公司参观考察，了解会员单位禹联网络货运平台发展运行情况，禹联网络董事长赵宏携公司管理团队陪同考察。</div>
                <div class="article-p-title">禹联网络调研沟通会</div>
                <div class="article-p">在随后召开的座谈会上，禹联网络董事长赵宏对晏庆华的到来表示热烈的欢迎，并对公司现阶段业务经营以及发展规划等方面进行了详细介绍。 </div>
                <div class="article-p">禹联网络成立于2020年6月，是一家基于物联网科技和数字化园区联动打造的公路货运数字化承运平台。 </div>
                <div class="article-p">“数字化”深深地刻在了禹联网络的发展基因中。从创立之初，禹联网络便以物联网技术、大数据、云计算为基础打磨物流企业数字化转型解决方案，致力成为行业先进的物流生态链数字化服务商。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic7_1.jpg" alt="">
                </div>
                <div class="article-p">晏庆华认真听取了赵宏对公司发展历程和业务规划介绍，充分肯定了禹联网络成立两年以来，从业务规模、技术创新等方面取得了飞速发展，并高度赞赏了禹联网络服务中小物流企业数字化转型中差异化服务优势。</div>
                <div class="article-p">晏庆华详细分析了网络货运的发展背景、特点、现状和趋势，并对网络货运相关政策给予了解读和指导。通过和管理团队的实时沟通,晏庆华对禹联网络货运平台的发展提出了宝贵的指导建议。</div>
                <div class="article-p">晏庆华主任重点指出，网络货运价值在于降本增效，对于网络货运企业企业来说，重点打造资源整合能力、数字化能力以及信用能力三大核心竞争力，踏上规范、健康、高质量的发展之路至关重要。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic7_2.jpg" alt="">
                </div>
                <div class="article-p">禹联网络董事长赵宏对此十分认同。他介绍，现阶段禹联网络以网络货运服务为核心，打造了三个数字化基础服务板块，分别是基于运力运营供应链的服务，基于大客户的定制化运营服务，基于中间层的SaaS+服务。</div>
                <div class="article-p">同时，禹联网络着重提升网络货运平台发展的外延性，通过“链接+整合”方式向垂直场景的实体运输拓展，和多家大型标杆企业结成合作伙伴关系，提供全方位降本增效解决方案。</div>
                <div class="article-p">通过此次深入的交流与分享，禹联网络更加坚定朝着“让数字物流，服务美好生活”的使命奋发前进。</div>
                <div class="article-p">未来，禹联网络将继续专注技术研发，强化差异化服务优势，在中国物流与采购联合会的指导和帮助下，推动数字物流生态圈发展建设，为行业与社会做出更大贡献。</div>

                <div class="article-source">编辑：禹联网络</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_7'
    }
</script>