import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter)

import About from "@/pages/About";
import ContactUs from "@/pages/ContactUs";
import DigitalFreight from "@/pages/DigitalFreight";
import Home from "@/pages/Home";
import NewsCenter from "@/pages/NewsCenter";
import NewsCenterDetail from "@/pages/NewsCenterDetail";


//定义routes路由的集合，数组类型
const routes=[
    {path: '/', redirect: '/home'},
    {path: '/home', component: Home},
    {path:'/about',component: About},
    {path:'/digital',component: DigitalFreight},
    {path:'/news',component: NewsCenter},
    {path:'/news/detail',component: NewsCenterDetail},
    {path:'/contact',component: ContactUs},
]

const router = new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes
});

export default router