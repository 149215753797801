<template>
    <div>
        <!-- banner图 -->
        <div class="digital-banner">
            <img src="../images/digital-freight/banner.png" width="100%" alt="">
        </div>

        <!-- 网络货运平台 -->
        <div class="dig-container" id="title1">
            <div class="container-wrapper network">
                <div class="title1 fontsize55 wow animated fadeInDown">网络货运平台 数字化转型一站式服务</div>
                <div class="title2 fontsize28 wow animated fadeInDown">
                    <div class="newTitle1">打破信息孤岛，以数字化方式重塑“人、车、货、场”的资源管理，<span>助力物流企业降本增效，向数字化转型升级</span></div>
                    <div class="newTitle2">助力物流企业降本增效，向数字化转型升级</div>
                </div>
                <ul class="ul-li-1">
                    <li class="fontsize30 wow animated flipInX" v-for="item in menuList1" :key="item.title">
                        <div class="img">
                            <img :src="imgUrl(item.img)" alt="" width="100%">
                        </div>
                        <div class="text">{{item.title}}</div>
                    </li>
                </ul>
                <ul class="ul-li-2">
                    <li class="fontsize20 wow animated flipInY" v-for="item in menuList2" :key="item.title">
                        <div class="img">
                            <img :src="imgUrl(item.img)" alt="" width="100%">
                        </div>
                        <div class="text">{{item.title}}</div>
                    </li>
                </ul>
                <ul class="ul-li-3">
                    <li class="wow animated flipInX" v-for="item in menuList3" :key="item.title">
                        <div class="img">
                            <img :src="imgUrl(item.img)" alt="" width="100%">
                        </div>
                        <div class="ul-li-3-title1 fontsize26">{{item.title}}</div>
                        <div class="ul-li-3-title2 fontsize20">{{item.subTitle}}</div>
                    </li>
                </ul>
                <ul class="ul-li-3">
                    <li class="wow animated flipInY" v-for="item in menuList4" :key="item.title">
                        <div class="img">
                            <img :src="imgUrl(item.img)" alt="" width="100%">
                        </div>
                        <div class="ul-li-3-title1 fontsize26">{{item.title}}</div>
                        <div class="ul-li-3-title2 fontsize20">{{item.subTitle}}</div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 定制解决方案 -->
        <div class="fangan-container" id="title2">
            <div class="container-wrapper network">
                <div class="title1 fontsize55 wow animated fadeInDown">定制解决方案 满足多元业务需求</div>
                <div class="title2 fontsize28 wow animated fadeInDown">
                    <div class="newTitle1">提供物联网模块、软件及系统开发等定制化产品与技术服务，有效<span>支持复杂场景和模式下的多元业务形态</span></div>
                    <div class="newTitle2">支持复杂场景和模式下的多元业务形态</div>
                </div>
                <ul class="ul-li-4">
                    <li class="wow animated flipInX" v-for="item in menuList5" :key="item.title">
                        <div class="img">
                            <img :src="imgUrl(item.img)" alt="" width="100%">
                        </div>
                        <div class="ul-li-1-title">
                            <span class="span1 fontsize32">{{item.title}}</span>
                            <span class="span2 fontsize22">{{item.subTitle}}</span>
                        </div>
                    </li>
                </ul>
                <ul class="ul-li-4">
                    <li class="wow animated flipInY" v-for="item in menuList5" :key="item.title">
                        <div class="img">
                            <img :src="imgUrl(item.img)" alt="" width="100%">
                        </div>
                        <div class="ul-li-1-title">
                            <span class="span1 fontsize32">{{item.title}}</span>
                            <span class="span2 fontsize22">{{item.subTitle}}</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <!-- 禹联网络，让数字物流，服务美好生活 -->
        <div class="number-container" id="title3">
            <div class="number-container-inner">
                <div class="inner-left wow animated slideInLeft">
                    <div class="img1"><img src="../images/digital-freight/logo.png" alt="" width="100%"></div>
                    <div class="img2"><img src="../images/digital-freight/logo-pic.png" alt="" width="100%"></div>
                    <div class="text-wrap">
                        <div class="text-wrap-inner">
                            <div class="text-wrap-title fontsize40">打造公路货运全产业链服务生态体系</div>
                            <div class="text-wrap-content fontsize20">
                                依托物联网、大数据、云计算等数字化技术，搭建信息服务链、产品链、大数据信用链实现行业全场景链式赋能，打造一个真正基于公路货运全产业链服务的生态体系，为行业高质量发展创造价值。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="inner-right wow animated slideInRight">
                    <div class="inner-right-img">
                        <img src="../images/digital-freight/process.png" width="100%" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WowMixin from "@/WowMixin";

    export default {
        name: 'DigitalFreight',
        mixins: [WowMixin],
        data() {
            return {
                menuList1: [
                    {img: 'icon1-1', title: '数字化'},
                    {img: 'icon1-2', title: '智能化'},
                    {img: 'icon1-3', title: '规范化'},
                ],
                menuList2: [
                    {img: 'icon2-1', title: '业务数字化'},
                    {img: 'icon2-2', title: '场景定制化'},
                    {img: 'icon2-3', title: '运输信息化'},
                    {img: 'icon2-4', title: '管理在线化'},
                    {img: 'icon2-5', title: '共享可视化'},
                    {img: 'icon2-6', title: '生态化链接'},
                ],
                menuList3: [
                    {img: 'icon3-1', title: '专业运营服务', subTitle: '专业团队 快速响应'},
                    {img: 'icon3-2', title: '运单全程可视化', subTitle: '轨迹追踪 全程追溯'},
                    {img: 'icon3-3', title: '运力资源共享', subTitle: '资源丰富 优化配置'},
                ],
                menuList4: [
                    {img: 'icon3-1', title: '安全合规发展', subTitle: '合规运营 风控管理'},
                    {img: 'icon3-4', title: '数字化解决方案', subTitle: '行业定制 自主研发'},
                    {img: 'icon3-5', title: '平台增值赋能', subTitle: '多平台协同 金融保险'},
                ],
                menuList5: [
                    {img: 'pic1', title: '物流三方', subTitle: '车源和调度管理'},
                    {img: 'pic2', title: '物流专线', subTitle: '货源和线路管理'},
                ],
                menuList6: [
                    {img: 'pic3', title: '网络物流', subTitle: '高效协同精细管理'},
                    {img: 'pic4', title: '大宗运输', subTitle: '可视化管理和配送体系'},
                ],
            }
        },
        methods: {
            imgUrl(img) {
                return require(`../images/digital-freight/${img}.png`)
            }
        }
    }
</script>

<style>

</style>