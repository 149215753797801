<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 媒体报道 &gt; 详情</div>
                <div class="article-title">祝贺！禹联网络董事长赵宏荣获“最美奋斗者”称号</div>
                <div class="article-time">来源：蚌埠日报 2023-09-20</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p">9月20日，由蚌埠市文明办，蚌埠日报社联合主办的“蚌埠市最美奋斗者”名单公示，禹联网络董事长赵宏被授予“最美奋斗者”荣誉称号！</div>
                <div class="article-p">“最美奋斗者”名单来自蚌埠市多家大型企业和非公经济组织，展示了各行各业人士“强国复兴，有我担当”强大精神凝聚力。为梦想拼搏实干，用劳动创造幸福！时代召唤更多的奋斗者，为努力建设幸福蚌埠、文明蚌埠贡献更多力量。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news2_pic8.jpg" alt="">
                </div>
                <div class="article-p">此前，禹联网络科技有限公司董事长赵宏作为蚌埠市禹会区提报的“最美奋斗者”候选人之一，在“劳动创造幸福”主题论坛暨“最美奋斗者”故事分享会，向现场的领导、嘉宾、企业家朋友们分享自己的奋斗历程。</div>
                <div class="article-p-title">【演讲节选】</div>
                <div class="article-p">1.创业失败是大概率事件，成功是小概率事件，但是因为坚韧，小概率事件常常发生。一家企业成功的基石还是价值观，企业家的价值导向会引领企业的战略愿景，所有企业的胜利，最后都是价值观的胜利。</div>
                <div class="article-p">2.数字经济时代到来，所有行业，都将在数字化浪潮中被重塑。在蚌埠市政府“双招双引”政策支持下，禹联网络在2020年6月成立，致力于成为行业先进的物流生态链数字化服务商，通过数字化手段实现中小物流企业转型升级、降本增效，回报行业与社会。</div>
                <div class="article-p">3.劳动创造幸福是结果，感恩铭记美好是延续。来到蚌埠这块热土，总是给予我们力量与温暖，良好的营商环境，为企业发展提供了重要保证。禹联网络将学习并弘扬新时期的徽商精神，不畏艰难、百折不挠，奋发向上，坚持创新驱动，推动当地经济发展，继续用劳动创造幸福！</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail2_8'
    }
</script>