<template>
    <div>
        <!--标题+路径-->
        <div class="article-top">
            <div class="container-wrapper">
                <div class="article-nav">首页 &gt; 新闻中心 &gt; 禹联动态 &gt; 详情</div>
                <div class="article-title">新里程碑！祝贺禹联网络荣获“国家高新技术企业”认定</div>
            </div>
        </div>

        <!--具体内容-->
        <div class="article-content">
            <div class="container-wrapper">
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic13_1.jpg" alt="">
                </div>
                <div class="article-p-title">禹联网络荣获“国家高新技术企业”认定</div>
                <div class="article-p">根据《高新技术企业认定管理办法》(国科发火〔2016〕32号)和《高新技术企业认定管理工作指引》(国科发火〔2016〕195号)有关规定，2023年10月16日，由全国高新技术企业认定管理工作领导小组办公室对外发布“安徽省认定机构2023年认定报备的第一批高新技术企业备案公示名单”。</div>
                <div class="article-p">禹联网络科技有限公司顺利通过并荣获“国家高新技术企业”认定，由此正式迈入国家高新技术企业行列。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic13_2.jpg" alt="">
                </div>
                <div class="article-p">成功入选国家“高新技术企业”名单，充分体现了禹联网络在数字化货运领域具备的技术创新能力和研发实力，也是公司坚守“让数字物流，服务美好生活”的初心使命，专心技术研发创新的重要里程碑见证，为禹联网络未来的发展奠定了坚实的基础。</div>
                <div class="article-p-title">物流数字化 技术赋能迈向创新生态</div>
                <div class="article-p">作为一家综合型公路货运承运平台，禹联网络科技在《国家重点支持的高新技术领域》内持续进行科技研发与技术成果转化工作，形成了核心自主知识产权。</div>
                <div class="article-p">公司拥有一支高素质、专业化的技术团队。禹联网络团队自主研发的禹联智慧货运平台系统，历经19个月持续研发，100+版本迭代升级，基于分布式微服务架构和敏捷开发的管理理念，打造了高可用的软件平台，满足司机、企业、平台运营等多段的联合作业需求。</div>
                <div class="article-p">通过模式创新和工具创新，完成了基于合规的订单审核模式，通过智能科技数学算法模型和物流全程如实报告，打造安全、合规的禹联网络平台。</div>
                <div class="article-p-img">
                    <img src="../../images/news-center/news_pic13_3.jpg" alt="">
                </div>
                <div class="article-p">成立三年以来，禹联网络不断夯实技术研发水平和自主创新能力，进一步提升禹联网络的市场竞争力和品牌价值，先后获得行业媒体及行业协会多项荣誉，为公司持续高质量发展注入强劲动力。</div>
                <div class="article-p">展望未来，禹联网络科技将以这次国家高新技术企业的认定为契机，不断开拓进取，坚持“诚信、奋斗、创新、卓越”的企业价值观，持续提升自主创新能力、强化知识产权，重视人才队伍培养，打造基于公路货运全产业链服务的生态体系，为行业带来价值。</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Detail1_13'
    }
</script>